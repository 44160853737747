<template>
  <v-card fluid class="fill-height view home px-4 pb-12 mt-12 mb-12">
    <TopBar>
      <template v-slot:title>
        <div>{{ $t('app.appname') }}</div>
        <v-img class="ml-2" transition="fade-transition" :alt="`${$t('app.appname')} (${$t('app.version')})`" src="@/assets/images/logo.png" max-height="24" max-width="24" />
      </template>
    </TopBar>

    <v-card flat v-if="dataLoaded">

      <NewsAnnouncement :items="news"/>

      <h5 class="pt-8 pb-2">{{ $t('album.recommended') }}</h5>
      <v-row dense class="single-row">
        <v-col v-for="item in recommended" :key="item.name" :cols="$vuetify.breakpoint.mobile ? 6 : 'auto'">
          <AlbumItem :item="item" showArtist />
        </v-col>
      </v-row>
      
      <h5 class="pt-4 pb-2">{{ $t('album.latest') }}</h5>
      <v-row dense>
        <v-col v-for="item in latest" :key="item.name" :cols="$vuetify.breakpoint.mobile ? 6 : 'auto'">
          <AlbumItem :item="item" showArtist />
        </v-col>
      </v-row>
      
    </v-card>

  </v-card>
</template>

<script>
import TopBar from '@/components/TopBar'
import { mapGetters, mapActions } from 'vuex'
import Lget from 'lodash/get'
import AlbumItem from '@/components/AlbumItem'
import NewsAnnouncement from '@/components/NewsAnnouncement.vue'

export default {
  metaInfo() {
    return {
      title: this.$t('home.home'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('home.home')
      }, {
        property: 'og:description',
        content: this.$t('home.default-meta-description')
      }]
    }
  },

  data: () => ({
    dataLoaded: false
  }),

  components: {
    AlbumItem,
    TopBar,
    NewsAnnouncement
  },

  async mounted() {
    await this.load()
    this.dataLoaded = true
  },

  computed: {
    ...mapGetters('albums', ['recommended', 'latest', 'news']),
  },

  methods: {
    ...mapActions('albums', ['getUserAlbums']),

    Lget: Lget,

    async load() {
      await this.getUserAlbums()
    },
  }

};
</script>

<style scoped>
.single-row {
  max-height: 300px !important;
  overflow-y: hidden;
}
</style>
