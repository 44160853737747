/* eslint-disable */
import Vue from 'vue'
import { Howl, Howler } from 'howler'
import Lget from 'lodash/get'
import API from '../../plugins/api'

const actions = {
  init() {
    return new Promise((resolve) => {
      Howler.autoUnlock = false
      return resolve(true)
    })
  },

  setUpActionHandlers({ dispatch }) {
    /* istanbul ignore next */
    if ('mediaSession' in navigator) {
      try {
        navigator.mediaSession.setActionHandler('play', async () => { await dispatch('play') })
      } catch (err) {
        console.log(err)
        console.log('Warning! The "play" media session action is not supported.')
      }
      try {
        navigator.mediaSession.setActionHandler('pause', () => { dispatch('pause') })
      } catch (err) {
        console.log('Warning! The "pause" media session action is not supported.')
      }
      try {
        navigator.mediaSession.setActionHandler('stop', () => { dispatch('stop') })
      } catch (err) {
        console.log('Warning! The "stop" media session action is not supported.')
      }
      try {
        navigator.mediaSession.setActionHandler('previoustrack', () => { dispatch('playPrev') })
      } catch (err) {
        console.log('Warning! The "previoustrack" media session action is not supported.')
      }
      try {
        navigator.mediaSession.setActionHandler('nexttrack', () => { dispatch('playNext') })
      } catch (err) {
        console.log('Warning! The "nexttrack" media session action is not supported.')
      }

      try {
        navigator.mediaSession.setActionHandler('seekbackward', async (event) => {
          await dispatch('startSeek')
          await dispatch('endSeek', '-' + String(event.seekOffset || 10))
        })
      } catch (err) {
        console.log('Warning! The "seekbackward" media session action is not supported.')
      }
      try {
        navigator.mediaSession.setActionHandler('seekforward', async (event) => {
          await dispatch('startSeek')
          await dispatch('endSeek', '+' + String(event.seekOffset || 10))
        })
      } catch (err) {
        console.log('Warning! The "seekforward" media session action is not supported.')
      }

      navigator.mediaSession.playbackState = 'none'
    }

  },

  async play({ dispatch }, song) {
    Vue?.$log?.info('player store - play')

    const howl = this.getters['player/howl']
    const currentVolume = this.getters['player/volume']
    const currentSong = this.getters['player/currentSong']

    // Continue where we were
    if (howl && !howl.playing() && (!song || currentSong.id === song.id)) {
      howl.play()
      return
    }

    // Stop that playing noice
    if (howl && howl.playing()) {
      dispatch('stop')
    }

    // Unload howl
    if (howl) {
      howl.unload()
    }

    if (!song && currentSong) {
      song = currentSong
    }

    // From here we need a song to continue
    if (!song) {
      return
    }

    const url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/songs/${song.id}/stream`

    try {
      const userData = JSON.parse(localStorage.getItem('user'))
      if (userData && userData.refreshToken) {
        await API.auth.refreshToken({ refreshToken: userData.refreshToken })
          .catch((err) => Promise.reject(new Error({ ...err })))
      }
    } catch (e) {
      return
    }

    await API.songs.play(song.id)

    /* istanbul ignore next */
    const newHowl = new Howl({
      src: [url],
      format: ['mp3'],
      html5: true,
      volume: currentVolume / 100,
      xhr: {
        method: 'GET',
        withCredentials: true
      },
      onplay() {
        dispatch('setPlaying', true)
      },
      onpause() {
        dispatch('setPlaying', false)
      },
      onstop() {
        dispatch('setPlaying', false)
      },
      onplayerror() {
        Vue?.prototype?.$events?.fire('showError', 'Failed to play song!')
      },
      async onloaderror(id, err) {
        switch (err) {
          case 1:
            dispatch('setPlaying', false)
            dispatch('playNext')
            // eslint-disable-next-line max-len
            Vue?.prototype?.$events?.fire('showError', "The fetching process for the media resource was aborted by the user agent at the user's request.")
            break;
          case 2:
            dispatch('setPlaying', false)
            dispatch('playNext')
            // eslint-disable-next-line max-len
            Vue?.prototype?.$events?.fire('showError', 'A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.')
            break;
          case 3:
            dispatch('setPlaying', false)
            dispatch('playNext')
            // eslint-disable-next-line max-len
            Vue?.prototype?.$events?.fire('showError', 'An error of some description occured while decoding the media resource, after the resource was established to be usable.')
            break;
          case 4:
            dispatch('setPlaying', false)
            dispatch('playNext')
            // eslint-disable-next-line max-len
            Vue?.prototype?.$events?.fire('showError', ' The media resource indicated by the src attribute or assigned media provider object was not suitable.')
            break;
          default:
            dispatch('setPlaying', false)
            dispatch('playNext')
            Vue?.prototype?.$events?.fire('showError', 'Failed to load song!')
            break;
        }
      },
      onend() {
        dispatch('setPlaying', false)
        dispatch('playNext')
      }
    })

    // Set stuff we need
    await dispatch('setHowl', newHowl)
    await dispatch('setTrack', song)
    await dispatch('configureAnalyzer')

    // Play that funky music white boy
    newHowl.play()

    if ('mediaSession' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.mediaSession.metadata = new MediaMetadata({
        title: Lget(song, 'songName', 'Unknown'),
        artist: Lget(song, 'album.artist.artistName', 'Unknown'),
        album: Lget(song, 'album.albumName', 'Unknown'),
        artwork: [
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 96), sizes: '96x96', type: 'image/png' },
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 128), sizes: '128x128', type: 'image/png' },
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 192), sizes: '192x192', type: 'image/png' },
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 256), sizes: '256x256', type: 'image/png' },
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 384), sizes: '384x384', type: 'image/png' },
          { src: API.albums.getAlbumUrl(Lget(song, 'album.uuid', -1), 512), sizes: '512x512', type: 'image/png' }
        ]
      });
      await dispatch('albums/playAlbumSong', song, { root: true })
      await dispatch('artists/playPopularSong', song, { root: true })

      await dispatch('setUpActionHandlers')
    }
  },

  async playThis({ dispatch }) {
    Vue?.$log?.info('player store - play queue')
    const queue = this.getters['player/queue']
    const queueIdx = this.getters['player/queueIdx']
    if (queue && queueIdx > -1 && queueIdx < queue.length) {
      await dispatch('play', queue[queueIdx])
    }
  },

  async playPrev({ dispatch }) {
    Vue?.$log?.info('player store - playprev')
    const queue = this.getters['player/queue']
    const queueIdx = this.getters['player/queueIdx']
    const loop = this.getters['player/loop']
    const shuffle = this.getters['player/shuffle']

    if (shuffle) {
      let index = Math.round(Math.random() * (queue.length - 1))
      /* istanbul ignore next */
      while (index === this.queueIdx && (queue.length - 1) > 0) {
        index = Math.round(Math.random() * (queue.length - 1))
      }
      await dispatch('setQueueIdx', index)
      await dispatch('play', queue[index])
      return
    }

    if (queue && queueIdx > 0) {
      await dispatch('setQueueIdx', queueIdx - 1)
      await dispatch('play', queue[queueIdx - 1])
      return
    }

    if (loop && queueIdx === 0) {
      await dispatch('setQueueIdx', queue.length - 1)
      await dispatch('play', queue[queue.length - 1])
    }
  },

  async playNext({ dispatch }) {
    Vue?.$log?.info('player store - playnext')
    const queue = this.getters['player/queue']
    const queueIdx = this.getters['player/queueIdx']
    const loop = this.getters['player/loop']
    const shuffle = this.getters['player/shuffle']

    if (shuffle) {
      let index = Math.round(Math.random() * (queue.length - 1))
      /* istanbul ignore next */
      while (index === queueIdx && (queue.length - 1) > 0) {
        index = Math.round(Math.random() * (queue.length - 1))
      }
      await dispatch('setQueueIdx', index)
      await dispatch('play', queue[index])
      return
    }

    if (queue && queueIdx > -1 && queueIdx < queue.length - 1) {
      await dispatch('setQueueIdx', queueIdx + 1)
      await dispatch('play', queue[queueIdx + 1])
      return
    }

    if (loop && queueIdx === queue.length - 1) {
      await dispatch('setQueueIdx', 0)
      await dispatch('play', queue[0])
    }
  },

  configureAnalyzer({ commit }) {
    Vue?.$log?.info('player store - configureAnalyzer')
    const haveAnalyzer = this.getters['player/analyzer']
    if (haveAnalyzer) { return }

    const howl = this.getters['player/howl']
    if (!howl) { return }

    /* istanbul ignore next */
    try {
      // eslint-disable-next-line no-underscore-dangle
      const html5element = Lget(howl, '_sounds[0]._node', null)
      if (!html5element) {
        commit('SET_ANALYZER', null)
        return
      }
      html5element.crossOrigin = 'use-credentials'
      const ctx = new (window.AudioContext || window.webkitAudioContext || window.mozAudioContext)()
      const analyzer = ctx.createAnalyser()
      const audioSrc = ctx.createMediaElementSource(html5element)
      audioSrc.connect(analyzer)
      analyzer.connect(ctx.destination)

      commit('SET_ANALYZER', analyzer)
    } catch (e) {
      commit('SET_ANALYZER', null)
    }
  },

  pause() {
    Vue?.$log?.info('player store - pause')
    const howl = this.getters['player/howl']
    if (!howl) { return }
    howl.pause()
  },

  stop() {
    Vue?.$log?.info('player store - stop')
    const howl = this.getters['player/howl']
    if (!howl) { return }
    howl.stop()
  },

  setPlaying({ commit }, isPlaying) {
    Vue?.$log?.info('player store - setPlaying')
    const howl = this.getters['player/howl']
    if (!howl) { return }
    const intervalFn = this.getters['player/intervalFn']
    if (isPlaying) {
      /* istanbul ignore next */
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'playing'
      }
      commit('SET_INTERVAL_FN', setInterval(() => {
        const seek = howl.seek()
        if (typeof seek !== 'object') {
          let duration = howl.duration()
          if (duration === Infinity) {
            const track = this.getters['player/currentSong']
            duration = Lget(track, 'duration', 0)
          }
          const pos = duration === 0 ? 0 : seek / duration

          /* istanbul ignore next */
          if ('mediaSession' in navigator) {
            // Since duration could be rounded to seconds, we need to make sure actual seek is not greater than duration
            navigator.mediaSession.setPositionState({
              duration: parseFloat(duration),
              playbackRate: parseFloat(1),
              position: parseFloat(seek > duration ? duration : seek) 
            });
          }
          const isSeeking = this.getters['player/isSeeking']
          if (!isSeeking) {
            commit('SET_PROGRESS', { seek, pos })
          }
        }
      }, 500))
    } else {
      /* istanbul ignore next */
      if ('mediaSession' in navigator) {
        navigator.mediaSession.playbackState = 'paused'
      }
      clearInterval(intervalFn)
      commit('SET_INTERVAL_FN', null)
    }
  },

  startSeek({ commit }) {
    Vue?.$log?.info('player store - startSeek')
    commit('SET_SEEKING', true)
  },

  endSeek({ commit }, val) {
    Vue?.$log?.info('player store - endSeek')
    commit('SET_SEEKING', false)
    commit('SET_POS', val)
  },

  setHowl({ commit }, howl) {
    Vue?.$log?.info('player store - setHowl')
    commit('SET_HOWL', howl)
  },

  setTrack({ commit }, track) {
    Vue?.$log?.info('player store - setTrack')
    commit('SET_TRACK', track)
  },

  setVolume({ commit }, volume) {
    Vue?.$log?.info('player store - setVolume')
    commit('SET_VOLUME', volume / 100)
  },

  setQueue({ commit }, queue) {
    Vue?.$log?.info('player store - setQueue')
    commit('SET_QUEUE', queue)
    commit('SET_QUEUE_IDX', 0)
  },

  addToQueue({ commit }, items) {
    Vue?.$log?.info('player store - addQueue')
    commit('ADD_TO_QUEUE', items)
  },

  setQueueIdx({ commit }, queueIdx) {
    Vue?.$log?.info('player store - setQueueIdx')
    commit('SET_QUEUE_IDX', queueIdx)
  },

  likeQueueSong({ commit }, data) {
    Vue?.$log?.info('player store - likeQueueSong')
    commit('UPDATE_QUEUE_SONG', data)
  },

  setAlbumArtShown({ commit }, data) {
    Vue?.$log?.info('player store - setAlbumArtShown')
    commit('SET_ALBUM_ART_SHOWN', data)
  },

  toggleMute({ commit }) {
    Vue?.$log?.info('player store - toggleMute')
    commit('TOGGLE_MUTE')
  },

  toggleShuffle({ commit }) {
    Vue?.$log?.info('player store - toggleShuffle')
    commit('TOGGLE_SHUFFLE')
  },

  toggleLoop({ commit }) {
    Vue?.$log?.info('player store - toggleLoop')
    commit('TOGGLE_LOOP')
  },

  toggleFullscreen({ commit }) {
    Vue?.$log?.info('player store - toggleFullscreen')
    commit('TOGGLE_FULLSCREEN')
  },

}

export default actions
