import Vue from 'vue'
import Lget from 'lodash/get'
// import Lmerge from 'lodash/merge'
import Lconcat from 'lodash/concat'
// import LsortBy from 'lodash/sortBy'
import LcloneDeep from 'lodash/cloneDeep'
import Lmerge from 'lodash/merge'
// import LsortBy from 'lodash/sortBy'
import i18n from '../../plugins/i18n'

import API from '../../plugins/api'

const actions = {

  async playSongs({ dispatch }, data) {
    Vue?.$log?.info('songs store - playSongs')
    // Add sorted songs with source information
    const queue = data.list.rows.map((s) => Lmerge(
      LcloneDeep(s),
      { source: { isQueued: false, displayName: i18n.t('track.liked'), route: { name: 'LikedSongs' } } }
    ))
    await dispatch('player/setQueue', queue, { root: true })
    await dispatch('player/setQueueIdx', data.startIdx, { root: true })
    await dispatch('player/playThis', null, { root: true })
  },

  // eslint-disable-next-line no-unused-vars
  async likeSong({ commit, dispatch }, { id, like }) {
    Vue?.$log?.info('songs store - likeSong')
    const res = await API.songs.like(id, like)
    dispatch('artists/likePopularSong', { id, like }, { root: true })
    dispatch('albums/likeAlbumSong', { id, like }, { root: true })
    dispatch('playlists/likePlaylistSong', { id, like }, { root: true })
    dispatch('player/likeQueueSong', { id, like }, { root: true })
    return res
  },

  async clearLikedSongs({ commit }) {
    commit('SET_LIKEDSONGS', {
      dirty: false, sorting: 'songName', filter: '', rows: [], count: 0
    })
  },

  async getLikedSongs({ commit }, {
    page, filter, sorting
  }) {
    Vue?.$log?.info('songs store - getLikedSongs')
    const pageSize = this.getters['songs/likedSongsPageSize']
    const likedSongs = this.getters['songs/likedSongs']

    // Got All or thoose we want?
    if (Lget(likedSongs, 'count', 0) > 0) {
      if (Lget(likedSongs, 'rows.length', 0) === Lget(likedSongs, 'count', 0)) {
        return likedSongs
      }
      if (page * pageSize <= Lget(likedSongs, 'rows.length', 0)) {
        return likedSongs
      }
    }

    const res = await API.songs.likedSongs({
      startAt: Lget(likedSongs, 'rows.length', 0),
      fetchSize: pageSize,
      filter,
      sorting
    })
    likedSongs.sorting = sorting
    likedSongs.filter = filter
    likedSongs.count = res.data.count
    likedSongs.rows = Lconcat(LcloneDeep(Lget(likedSongs, 'rows', [])), res.data.rows)
    commit('SET_LIKEDSONGS', likedSongs)

    return likedSongs
  }
}

export default actions
