import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

import 'typeface-roboto-condensed/index.css'
import 'typeface-eczar/index.css'
import 'typeface-anton/index.css'

import i18n from './i18n'

require('typeface-roboto-condensed')
require('typeface-eczar')
require('typeface-anton')

Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#1EB980',
        secondary: '#045D56',
        tertiary: '#FF6859',
        quaternary: '#FFCF44',
        quinary: '#B15DFF',
        senary: '#72DEFF',

        background: '#33333d',
        dialogbackground: '#1F1E1F',
        playerbackground: '#27272F',
        cardbackground: '#373740',

        textcolor: '#FFFFFF'
      }
    },
    options: { customProperties: true, variations: false }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  },
  error: {
    actions: {
      true: {
        text: i18n.t('buttons.close'),
        color: 'primary'
      }
    }
  },
  confirm: {
    actions: {
      false: i18n.t('buttons.cancel'),
      true: {
        text: i18n.t('buttons.ok'),
        color: 'primary'
      }
    }
  }
})

export default vuetify
