<template>
  <v-navigation-drawer
    class="main-drawer"
    :class="{'below-player': hasQueue}"
    permanent
    app
    @input="$emit('input',toggle)"
  >
    <v-list nav dense>
      <v-list-item exact :to="{name: 'Home'}" :aria-label="$t('home.home')">
        <v-list-item-icon>
          <v-icon>{{ mdiHomeOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('home.home') }}</v-list-item-title>
      </v-list-item>

      <v-list-item exact :to="{name: 'Search', params: {cleanSearch: true}}"  :aria-label="$t('search.search')">
        <v-list-item-icon>
          <v-icon>{{ mdiMagnify }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('search.search') }}</v-list-item-title>
      </v-list-item>

      <v-list-item exact :to="{name: 'Library', query: {tab: 'playlists'}}" :aria-label="$t('library.library')">
        <v-list-item-icon>
          <v-icon>{{ mdiBookshelf }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('library.library') }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list nav dense>

      <v-list-item exact @click="createPlaylist" :aria-label="$t('playlist.create')">
        <v-list-item-icon>
          <v-icon>{{ mdiPlusBox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('playlist.create') }}</v-list-item-title>
      </v-list-item>

      <v-list-item exact :to="{name: 'LikedSongs'}" :aria-label="$t('track.liked')">
        <v-list-item-icon>
          <v-icon>{{ mdiHeartBox }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('track.liked') }}</v-list-item-title>
      </v-list-item>

    </v-list>

    <v-divider></v-divider>
    
    <SideNavPlaylists class="playlist-container" :class="{ 'with-album-art': albumArtShown, 'no-player': !hasQueue}" transition="expand-x-transition"/>
    <AlbumArt v-if="albumArtShown" @hide="setAlbumArtShown(false)" transition="expand-x-transition"/>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiMagnify, mdiHomeOutline, mdiBookshelf, mdiPlusBox, mdiHeartBox } from '@mdi/js'
import SideNavPlaylists from '@/components/SideNavPlaylists.vue'
import AlbumArt from '@/components/AlbumArt.vue'

export default {
  props: ['value'],

  data: () => ({
    mdiMagnify,
    mdiHomeOutline,
    mdiBookshelf,
    mdiPlusBox,
    mdiHeartBox,
    toggle: false
  }),

  components: {
    SideNavPlaylists,
    AlbumArt
  },

  watch: {
    value: function (newVal, oldVal) {
      this.toggle = newVal
    }
  },

  computed: {
    ...mapGetters('player', ['albumArtShown', 'hasQueue'])
  },

  methods: {
    ...mapActions('playlists', ['createPlaylistWithoutTrack']),

    async createPlaylist() {
      const newPlaylist = await this.createPlaylistWithoutTrack({playlistName: this.$t('playlist.new-name')})
      this.$nextTick(() => {
        this.$router.push({ name: 'Playlist', params: {id: newPlaylist.id} }).catch(err => {})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-drawer.below-player {
  z-index: 2 !important;
  height: calc(100vh - 80px) !important;
}
.playlist-container {
  z-index: 2 !important;
  height: calc(100vh - 325px) !important;
}
.playlist-container.no-player {
  height: calc(100vh - 245px) !important;
}
.playlist-container.with-album-art {
  height: calc(100vh - 581px) !important;
}
.theme--dark.v-navigation-drawer .v-divider {
  margin-left: 15px;
  margin-right: 15px;
  border-color: rgba(255, 255, 255, 0.3);
}
</style>