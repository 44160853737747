import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const albumsModule = {
  namespaced: true,
  state: {
    pageSize: 50,
    userAlbums: {},
    userGenres: {},
    album: {},
    libraryAlbums: {
      dirty: false, browser: '', context: 'liked', sorting: 'album', filter: '', rows: [], count: 0
    },
    genreAlbums: {
      dirty: false, genre: 'rock', browser: '', context: 'all', sorting: 'date', filter: '', rows: [], count: 0
    }
  },
  getters,
  actions,
  mutations
}

export default albumsModule
