<template>
  <v-app-bar height="48" dense flat fixed :app="isApp" :class="{shortend: !$vuetify.breakpoint.mobile, hidden: isFullscreen && !$vuetify.breakpoint.mobile}" :style="`background-color: ${color} !important;`">
    <NavButtons :shaded="isTransparent"/>
    <transition appear name="fade-transition" :duration="{enter: 500, leave: 800 }">
      <v-toolbar-items v-if="!isTransparent && showPlay && !$vuetify.breakpoint.mobile" class="mx-2">
        <v-btn v-if="isPlaying" icon @click="$emit('pause')" class="round-play-button" data-test-id="topBarPauseBtn">
          <v-icon large color="primary">{{ mdiPauseCircle }}</v-icon>
        </v-btn>
        <v-btn v-else icon @click="$emit('play')" class="round-play-button" data-test-id="topBarPlayBtn">
          <v-icon large color="primary">{{ mdiPlayCircle }}</v-icon>
        </v-btn>
      </v-toolbar-items>
    </transition>
    <v-toolbar-title class="d-flex flex-row justify-start align-center" transition="fade-transition">
      <slot name="title" v-if="!isTransparent">Title</slot>
    </v-toolbar-title>
    <v-toolbar-items class="ml-8">
      <slot></slot>
    </v-toolbar-items>
    <v-spacer />
    <UserMenu />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NavButtons from '@/components/NavButtons.vue'
import UserMenu from '@/components/UserMenu.vue'
import { mdiPlayCircle, mdiPauseCircle } from '@mdi/js'

export default {

  props: {
    isApp: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    transparency: {
      type: Number,
      detault: 1
    },
    showPlay: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiPlayCircle,
    mdiPauseCircle,
    drawer: false
  }),

  components: {
    NavButtons,
    UserMenu
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'isFullscreen']),

    color() {
      return `rgb(39, 39, 39, ${this.transparency})`
    }

  },

  methods: {
  }
}
</script>

<style scoped>
.v-toolbar__items > .v-btn.round-play-button {
  border-radius: 50% !important;
}

header.shortend {
  left: 255px !important;
}

header.hidden {
  display: none !important;
}

</style>
