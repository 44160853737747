const zeroPad = (num, places) => String(num).padStart(places, '0')

const filters = {
  pad2digit: (value) => (value || value === 0 ? zeroPad(value, 2) : ''),

  minutes: (value) => {
    if (!value || typeof value !== 'number') return '00:00'
    let min = parseInt(value / 60, 10);
    let sec = parseInt(value % 60, 10)
    min = min < 10 ? zeroPad(min, 2) : min
    sec = sec < 10 ? zeroPad(sec, 2) : sec
    // eslint-disable-next-line no-param-reassign
    value = `${min}:${sec}`
    return value
  },

  datetime: (value) => {
    try {
      const date = new Date(value)
      // eslint-disable-next-line max-len
      return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)} ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
    } catch (e) {
      /* istanbul ignore next */
      return ''
    }
  },

  date: (value) => {
    try {
      const date = new Date(value)
      return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`
    } catch (e) {
      /* istanbul ignore next */
      return ''
    }
  },

  time: (value) => {
    try {
      const date = new Date(value)
      // eslint-disable-next-line max-len
      return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
    } catch (e) {
      /* istanbul ignore next */
      return ''
    }
  },

  bio: (value) => {
    try {
      const div = document.createElement('div')
      div.innerHTML = value
      /* istanbul ignore next */
      const text = div.textContent || div.innerText || ''
      return text
        .replace('Read more on Last.fm', '')
        .replace(/_+/g, '')
        .replace(/^\[edit\]/g, '')
        .replace(/\s+\.\s+/g, ' ')
        .replace('User-contributed text is available under the Creative Commons By-SA License; additional terms may apply.', '')
    } catch (e) {
      /* istanbul ignore next */
      return ''
    }
  }
}

export default filters
