<template>
  <v-slider :class="{big: big}" hide-details :value="progress" min="0" max="100" class="duration-slider" @start="seekStart" @end="seekEnd"></v-slider>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    big: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('player', ['progress']),
  },

  methods: {
    ...mapActions('player', ['startSeek', 'endSeek']),

    seekStart(v) {
      this.startSeek()
    },
    seekEnd(v) {
      this.endSeek(v)
    }
  }
}
</script>

<style scoped>
.duration-slider >>> .v-slider__thumb-container {
  display: none !important;
}

.duration-slider:hover >>> .v-slider__thumb-container {
  display: block !important;
}

.duration-slider >>> .v-slider {
  min-height: 10px;
  margin-left: 8px;
  margin-right: 8px;
}

.duration-slider.big >>> .v-slider__track-container {
  margin-top: 1px;
  height: 6px !important;
}

</style>