import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
// import goTo from 'vuetify/lib/services/goto'

import store from '../store'

Vue.use(VueRouter);

/*
const scrollBehavior = (to, from, savedPosition) => new Promise((resolve) => {
  let scrollTo = 0

  if (to.hash && to.hash !== '') {
    scrollTo = to.hash
  } else if (savedPosition) {
    scrollTo = savedPosition.y
  }

  setTimeout(() => {
    resolve(goTo(scrollTo))
  }, 250)
})
*/

const scrollBehavior = () => ({ x: 0, y: 0 })

const router = new VueRouter({
  scrollBehavior,
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = await store.getters['users/isLoggedIn']
  if (authRequired && !loggedIn && from.path !== '/login') {
    return next('/login')
  }
  return next()
})

router.state = []
router.statePointer = -1
router.canGoBack = () => router.statePointer > 0
router.canGoForward = () => router.state.length - 1 > router.statePointer

router.afterEach((to, from) => {
  if (router.statePointer > 0
    && to.fullPath === router.state[router.statePointer - 1].fullPath
    && from.fullPath === router.state[router.statePointer].fullPath) {
    // back
    router.statePointer -= 1
  } else if (router.statePointer !== -1
    && router.state.length - 1 > router.statePointer
    && to.fullPath === router.state[router.statePointer + 1].fullPath
    && from.fullPath === router.state[router.statePointer].fullPath) {
    // forward
    router.statePointer += 1
  } else {
    // new path
    router.statePointer += 1
    router.state.push(to)
  }
})

export default router
