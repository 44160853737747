const LfindIndex = require('lodash/findIndex')
const Lmerge = require('lodash/merge')
const Lget = require('lodash/get')

const mutations = {
  SET_LIBRARY_ALBUMS(state, items) {
    state.libraryAlbums = items
  },
  SET_GENRE_ALBUMS(state, items) {
    state.genreAlbums = items
  },
  SET_USER_ALBUMS(state, items) {
    state.userAlbums = items
  },
  UPDATE_USER_GENRE(state, item) {
    const genreAlbums = Lget(state, 'genreAlbums', null)
    if (genreAlbums) {
      const genreIdx = LfindIndex(Lget(state, 'genreAlbums.genres', []), ['genre', item.genre])
      if (genreIdx > -1) {
        const mergedData = JSON.parse(JSON.stringify(state.genreAlbums))
        mergedData.genres[genreIdx] = item
        state.genreAlbums = mergedData
      }
    }
  },
  UPDATE_LATEST_USER_ALBUMS(state, item) {
    if (item.id !== Lget(state, 'userAlbums.latest.rows[0].id', null)) {
      state?.userAlbums?.latest?.rows?.pop()
      state?.userAlbums?.latest?.rows?.unshift(item)
    }
  },
  SET_USER_GENRES(state, items) {
    state.userGenres = items
  },
  SET_ALBUM(state, album) {
    state.album = album
  },
  UPDATE_ALBUM(state, data) {
    const mergedData = Lmerge(JSON.parse(JSON.stringify(state.album)), data)
    state.album = mergedData
  },
  LIKE_ALBUM_SONG(state, data) {
    const songIdx = LfindIndex(Lget(state, 'album.songs', []), ['id', data.id])
    if (songIdx > -1) {
      const mergedData = JSON.parse(JSON.stringify(state.album))
      mergedData.songs[songIdx].likes = data.like
      state.album = mergedData
    }
  },
  PLAY_ALBUM_SONG(state, data) {
    const songIdx = LfindIndex(Lget(state, 'album.songs', []), ['id', data.id])
    if (songIdx > -1) {
      const mergedData = JSON.parse(JSON.stringify(state.album))
      mergedData.songs[songIdx].plays += 1
      state.album = mergedData
    }
  }

}

export default mutations
