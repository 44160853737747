import Vue from 'vue'
import VueEvents from 'vue-events'
import VueMeta from 'vue-meta'
import VueLogger from 'vuejs-logger'
import { Notifier } from '@airbrake/browser'
import AsyncComputed from 'vue-async-computed'
import App from './App.vue'
import router from './router'
import store from './store'
import API from './plugins/api'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import './filters'

import './styles/main.scss'

Vue.config.productionTip = false

const logOptions = {
  isEnabled: true,
  logLevel: (process.env.NODE_ENV === 'production') ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

if (process.env.NODE_ENV === 'production') {
  const airbrake = new Notifier({
    environment: 'production',
    projectId: process.env.VUE_APP_AIRBRAKE_PROJECT_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_PROJECT_KEY
  });

  // eslint-disable-next-line func-names
  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      params: { info }
    });
  }
}

Vue.use(AsyncComputed)
Vue.use(VueEvents)
Vue.use(VueLogger, logOptions)
Vue.use(VueMeta)

const startup = async () => {
  try {
    await API.init({ router, store })
    await store.dispatch('init')
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App)
    }).$mount('#app')
  } catch (err) {
    Vue?.$log?.fatal('Failed to initialize app:', err)
  }
}

startup()
