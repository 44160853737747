import Lget from 'lodash/get'
import Lmerge from 'lodash/merge'
import Lconcat from 'lodash/concat'
import LsortBy from 'lodash/sortBy'
import LcloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import API from '../../plugins/api'

const actions = {
  async getUserGenres({ commit }) {
    Vue?.$log?.info('albums store - getUserGenres')

    let userGenres = await this.getters['albums/userGenres']

    // Cache fetch for 30 minutes
    if (userGenres && userGenres.fetchedAt) {
      const diff = Math.abs(new Date() - new Date(userGenres.fetchedAt))
      const minutesAgo = Math.floor((diff / 1000) / 60)
      if (minutesAgo < 30) {
        return userGenres
      }
    }

    const res = await API.albums.userGenres()
    userGenres = { genres: Lget(res, 'data', {}), fetchedAt: new Date() }
    commit('SET_USER_GENRES', userGenres)

    return userGenres
  },

  async updateUserGenre({ commit }, userGenre) {
    Vue?.$log?.info('albums store - updateUserGenre')

    commit('UPDATE_USER_GENRE', userGenre)

    return userGenre
  },

  async getUserAlbums({ commit }) {
    Vue?.$log?.info('albums store - getUserAlbums')

    let userAlbums = this.getters['albums/userAlbums']

    // Cache fetch for 15 minutes
    if (userAlbums && userAlbums.fetchedAt) {
      const diff = Math.abs(new Date() - new Date(userAlbums.fetchedAt))
      const minutesAgo = Math.floor((diff / 1000) / 60)
      if (minutesAgo < 15) {
        return userAlbums
      }
    }

    const res = await API.albums.userAlbums()
    userAlbums = { ...Lget(res, 'data', {}), fetchedAt: new Date() }
    commit('SET_USER_ALBUMS', userAlbums)

    return userAlbums
  },

  async updateLatestAlbums({ commit }, album) {
    Vue?.$log?.info('albums store - updateLatestAlbums')
    commit('UPDATE_LATEST_USER_ALBUMS', album)
  },

  async clearGenreAlbums({ commit }, defaults = {}) {
    commit('SET_GENRE_ALBUMS', Lmerge({
      dirty: false, genre: 'rock', browser: '', context: 'all', sorting: 'date', filter: '', rows: [], count: 0
    }, defaults))
  },

  async getGenreAlbums({ commit }, {
    genre, browser, context, page, filter, sorting
  }) {
    const message = `albums store - getGenreAlbums (page: ${page})`
    Vue?.$log?.info(message)

    const pageSize = await this.getters['albums/albumPageSize']
    const genreAlbums = await this.getters['albums/genreAlbums']

    // Got All or thoose we want?
    if (Lget(genreAlbums, 'count', 0) > 0) {
      if (Lget(genreAlbums, 'rows.length', 0) === Lget(genreAlbums, 'count', 0)
        && genreAlbums.context === context
        && genreAlbums.sorting === sorting) {
        return genreAlbums
      }
      if (page * pageSize <= Lget(genreAlbums, 'rows.length', 0)
        && genreAlbums.context === context
        && genreAlbums.sorting === sorting) {
        return genreAlbums
      }
    }

    // Fetch
    let apiCall = 'likedAlbums'
    if (context === 'all') {
      apiCall = 'allAlbums'
    }
    const res = await API.albums[apiCall]({
      startAt: Lget(genreAlbums, 'rows.length', 0) + (Lget(genreAlbums, 'rows.length', 0) > 0 ? 1 : 0),
      fetchSize: pageSize,
      filter,
      sorting,
      browser,
      genre
    })
    genreAlbums.genre = genre
    genreAlbums.browser = browser
    genreAlbums.context = context
    genreAlbums.sorting = sorting
    genreAlbums.filter = filter
    genreAlbums.count = res.data.count
    genreAlbums.rows = Lconcat(LcloneDeep(Lget(genreAlbums, 'rows', [])), res.data.rows)
    await commit('SET_GENRE_ALBUMS', genreAlbums)

    return genreAlbums
  },

  async clearLibraryAlbums({ commit }, defaults = {}) {
    commit('SET_LIBRARY_ALBUMS', Lmerge({
      dirty: false, browser: '', context: 'liked', sorting: 'album', filter: '', rows: [], count: 0
    }, defaults))
  },

  async getLibraryAlbums({ commit }, {
    browser, context, page, filter, sorting
  }) {
    const message = `albums store - getLibraryAlbums (page: ${page})`
    Vue?.$log?.info(message)

    const pageSize = await this.getters['albums/albumPageSize']
    const libraryAlbums = await this.getters['albums/libraryAlbums']

    // Got All or thoose we want?
    if (Lget(libraryAlbums, 'count', 0) > 0) {
      if (Lget(libraryAlbums, 'rows.length', 0) === Lget(libraryAlbums, 'count', 0)
        && libraryAlbums.context === context
        && libraryAlbums.sorting === sorting) {
        return libraryAlbums
      }
      if (page * pageSize <= Lget(libraryAlbums, 'rows.length', 0)
        && libraryAlbums.context === context
        && libraryAlbums.sorting === sorting) {
        return libraryAlbums
      }
    }

    // Fetch
    let apiCall = 'likedAlbums'
    if (context === 'all') {
      apiCall = 'allAlbums'
    }
    const res = await API.albums[apiCall]({
      startAt: Lget(libraryAlbums, 'rows.length', 0) + (Lget(libraryAlbums, 'rows.length', 0) > 0 ? 1 : 0),
      fetchSize: pageSize,
      filter,
      sorting,
      browser
    })
    libraryAlbums.browser = browser
    libraryAlbums.context = context
    libraryAlbums.sorting = sorting
    libraryAlbums.filter = filter
    libraryAlbums.count = res.data.count
    libraryAlbums.rows = Lconcat(LcloneDeep(Lget(libraryAlbums, 'rows', [])), res.data.rows)
    await commit('SET_LIBRARY_ALBUMS', libraryAlbums)

    return libraryAlbums
  },

  async getAlbum({ commit }, id) {
    Vue?.$log?.info('albums store - getAlbum')

    let album = this.getters['albums/album']

    if (Lget(album, 'id', null) === id) {
      return album
    }

    const res = await API.albums.get(id)
    album = Lget(res, 'data', {})
    await commit('SET_ALBUM', album)

    return album
  },

  // eslint-disable-next-line no-unused-vars
  async playAlbumSong({ commit }, { id }) {
    Vue?.$log?.info('albums store - playAlbumSong')

    commit('PLAY_ALBUM_SONG', { id })
  },

  // eslint-disable-next-line no-unused-vars
  async likeAlbumSong({ commit }, { id, like }) {
    Vue?.$log?.info('albums store - likeAlbumSong')

    commit('LIKE_ALBUM_SONG', { id, like })
  },

  // eslint-disable-next-line no-unused-vars
  async likeAlbum({ commit }, { id, like }) {
    Vue?.$log?.info('albums store - likeAlbum')

    const res = await API.albums.like(id, like)
    commit('UPDATE_ALBUM', { likes: like })
    return res
  },

  async playAlbum({ dispatch }, data) {
    Vue?.$log?.info('albums store - playAlbum')

    // Add sorted songs with source information
    const queue = LsortBy(data.album.songs.map((s) => Lmerge(
      LcloneDeep(s),
      { source: { isQueued: false, displayName: data.album.albumName, route: { name: 'Album', params: { id: data.album.id } } } }
    )), ['trackNum'])
    await dispatch('player/setQueue', queue, { root: true })
    await dispatch('player/setQueueIdx', data.startIdx, { root: true })
    await dispatch('player/playThis', null, { root: true })
    await dispatch('albums/updateLatestAlbums', data.album, { root: true })
    await API.albums.play(data.album.id)
  }
}

export default actions
