import LisEmpty from 'lodash/isEmpty'
import Lget from 'lodash/get'

const getters = {
  isLoggedIn: (state) => !LisEmpty(state.user),
  refreshToken: (state) => Lget(state, 'user.refreshToken', null),
  user: (state) => state.user,
  locale: (state) => Lget(state, 'user.locale', null)
}

export default getters
