import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const albumsModule = {
  namespaced: true,
  state: {
    pageSize: 500,
    likedSongs: {
      dirty: false, sorting: 'songName', filter: '', rows: [], count: 0
    }
  },
  getters,
  actions,
  mutations
}

export default albumsModule
