/* eslint-disable no-return-await */
import * as Vibrant from 'node-vibrant'
import Lget from 'lodash/get'

const VibrantMixin = {

  data: () => ({
    gradientBackground: null
  }),

  watch: {
    /* not testable in jsdom :-( */
    /* istanbul ignore next */
    gradientColor(newV) {
      if (newV) {
        this.$nextTick(() => {
          this.gradientBackground = `linear-gradient(${newV} 0%, rgba(255,0,0,0) 410px) !important`
        })
      } else {
        this.gradientBackground = 'linear-gradient(rgba(255,0,0,0) 0%, rgba(255,0,0,0) 410px) !important'
      }
    }
  },

  asyncComputed: {
    /* not testable in jsdom :-( */
    /* istanbul ignore next */
    async gradientColor() {
      return await this.getColorPaletteFromBase64(this.albumArt)
        .then((cp) => {
          const rgb = Lget(cp, 'Muted._rgb', null)
          if (rgb) {
            return `rgb(${rgb.join(', ')})`
          }
          return Lget(this, '$vuetify.theme.defaults.dark.playerbackground', '#cecece')
        })
    }
  },

  methods: {
    /* not testable in jsdom :-( */
    /* istanbul ignore next */
    async getColorPaletteFromBase64(base64String) {
      return new Promise((resolve, reject) => {
        try {
          const img = document.createElement('img');
          img.src = base64String;
          img.addEventListener(
            'load',
            async () => {
              const vibrant = new Vibrant(img);
              const palette = await vibrant.getPalette();
              resolve(palette);
            }
          )
        } catch (err) {
          reject(err)
        }
      })
    }
  }
}

export default VibrantMixin
