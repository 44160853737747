import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const usersModule = {
  namespaced: true,
  state: {
    user: null
  },
  getters,
  actions,
  mutations
}

export default usersModule
