import Vue from 'vue'
import Lget from 'lodash/get'
import Lmerge from 'lodash/merge'
import Lpick from 'lodash/pick'
import Lconcat from 'lodash/concat'
import LsortBy from 'lodash/sortBy'
import LcloneDeep from 'lodash/cloneDeep'
import Lclone from 'lodash/clone'
import API from '../../plugins/api'
import i18n from '../../plugins/i18n'

const actions = {
  async resortPlaylist({ commit }, items) {
    Vue?.$log?.info('playlist store - resortPlaylists')
    const playlist = this.getters['playlists/playlist']

    const changedEntries = []

    // eslint-disable-next-line no-restricted-syntax
    for (const [i, item] of items.entries()) {
      if (Lget(item, 'PlaylistItem.sortOrder', -1) !== i + 1) {
        const changedEntry = Lpick(Lclone(item), ['PlaylistItem'])
        // Lyrics in song might cause CORS problems
        delete changedEntry.PlaylistItem.song
        changedEntry.PlaylistItem.newOrder = i + 1
        changedEntries.push(changedEntry)
        item.PlaylistItem.sortOrder = i + 1
      }
    }

    try {
      await API.playlists.resort(playlist.id, changedEntries)
      await commit('UPDATE_PLAYLIST', { songs: items })
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - getUserPlaylists failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-add'))
    }
  },

  async getUserPlaylists({ commit }, force = false) {
    Vue?.$log?.info('playlist store - getUserPlaylists')
    let userPlaylists = this.getters['playlists/playlists']

    if (userPlaylists && userPlaylists[0] && !force) {
      return userPlaylists
    }

    try {
      const res = await API.playlists.list()
      userPlaylists = Lget(res, 'data.rows', [])
      commit('SET_PLAYLISTS', userPlaylists)

      return userPlaylists
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - getUserPlaylists failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-load'))
      return []
    }
  },

  async getPlaylist({ commit }, id) {
    Vue?.$log?.info('playlist store - getPlaylists')
    let playlist = this.getters['playlists/playlist']

    if (playlist && playlist.id === id) {
      return playlist
    }

    try {
      const res = await API.playlists.get(id)
      playlist = Lget(res, 'data', {})
      commit('SET_PLAYLIST', playlist)

      return playlist
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - getPlaylists failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-load'))
      return {}
    }
  },

  async playPlaylist({ dispatch }, data) {
    Vue?.$log?.info('playlist store - playPlaylists')
    // Add sorted songs with source information
    const queue = LsortBy(data.playlist.songs.map((s) => Lmerge(
      LcloneDeep(s),
      { source: { isQueued: false, displayName: data.playlist.name, route: { name: 'Playlist', params: { id: data.playlist.id } } } }
    )), ['PlaylistItem.sortOrder'])
    await dispatch('player/setQueue', queue, { root: true })
    await dispatch('player/setQueueIdx', data.startIdx, { root: true })
    await dispatch('player/playThis', null, { root: true })
    await API.playlists.play(data.playlist.id)
    await dispatch('getUserPlaylists', true)
  },

  async createPlaylistWithoutTrack({ dispatch }, data) {
    Vue?.$log?.info('playlist store - createPlaylistWithoutTrack')
    try {
      const res = await API.playlists.create(data)
      dispatch('getUserPlaylists', true)
      return res
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - createPlaylistWithoutTrack failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-add'))
      return null
    }
  },

  async createPlaylistWithTrack({ dispatch }, { track }) {
    Vue?.$log?.info('playlist store - createPlaylistWithTrack')
    try {
      await API.playlists.create(track)
      dispatch('getUserPlaylists', true)
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - createPlaylistWithTrack failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-add'))
    }
  },

  async addPlaylistTrack({ commit }, { id, track }) {
    Vue?.$log?.info('playlist store - addPlaylistTrack')
    try {
      const res = await API.playlists.addTrack(id, track.id)
      const newItem = LcloneDeep(track)
      newItem.PlaylistItem = res.track
      const playlist = this.getters['playlists/playlist']
      // If we're adding to current playlist
      if (playlist.id === Number(id)) {
        await commit('ADD_PLAYLIST_SONG', { id, track: newItem })
        if (res.coverChanged) {
          // Reload cover
          const cover = await API.playlists.getCover(id)
          await commit('UPDATE_PLAYLIST', { imageSrc: cover.url })
        }
      }
      return newItem
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - addPlaylistTrack failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-add'))
      return null
    }
  },

  async removePlaylistTrack({ commit }, { id, itemId }) {
    Vue?.$log?.info('playlist store - removePlaylistTrack')
    try {
      const res = await API.playlists.removeTrack(id, itemId)
      const playlist = this.getters['playlists/playlist']
      // If we're removing from current playlist
      if (playlist.id === id) {
        await commit('REMOVE_PLAYLIST_ITEM', { id, itemId })
        if (res.coverChanged) {
          // Reload cover
          const cover = await API.playlists.getCover(id)
          await commit('UPDATE_PLAYLIST', { imageSrc: cover.url })
        }
      }
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - removePlaylistTrack failed' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-delete'))
    }
  },

  async likePlaylistSong({ commit }, { id, like }) {
    Vue?.$log?.info('playlist store - likePlaylistSong')
    commit('UPDATE_PLAYLIST_SONG', { id, like })
  },

  async likePlaylist({ commit, dispatch }, { id, like }) {
    Vue?.$log?.info('playlist store - likePlaylist')
    try {
      const res = await API.playlists.like(id, like)
      await commit('UPDATE_PLAYLIST', { likes: like })
      dispatch('getUserPlaylists', true)
      return res
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - likePlaylist failed' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-change'))
      return {}
    }
  },

  async updatePlaylist({ commit, dispatch }, { id, editData }) {
    Vue?.$log?.info('playlist store - updatePlaylist')
    try {
      const res = await API.playlists.update(id, editData)
      await commit('UPDATE_PLAYLIST', editData)
      dispatch('getUserPlaylists', true)
      return res
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - updatePlaylist failed' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-change'))
      return {}
    }
  },

  async deletePlaylist({ commit, dispatch }, id) {
    Vue?.$log?.info('playlist store - deletePlaylist')
    try {
      const res = await API.playlists.delete(id)
      commit('SET_PLAYLIST', {})
      dispatch('getUserPlaylists', true)
      return res
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - deletePlaylist failed' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-delete'))
      return null
    }
  },

  async clearLibraryPlaylists({ commit }, defaults = {}) {
    commit('SET_LIBRARY_PLAYLISTS', Lmerge({
      dirty: false, browser: '', context: 'liked', sorting: 'playlist', filter: '', rows: [], count: 0
    }, defaults))
  },

  async getLibraryPlaylists({ commit }, {
    browser, context, page, filter, sorting
  }) {
    const message = `playlists store - getLibraryPlaylists (page: ${page})`
    Vue?.$log?.info(message)

    const pageSize = await this.getters['playlists/playlistPageSize']
    const libraryPlaylists = await this.getters['playlists/libraryPlaylists']

    // Got All or thoose we want?
    if (Lget(libraryPlaylists, 'count', 0) > 0) {
      if (Lget(libraryPlaylists, 'rows.length', 0) === Lget(libraryPlaylists, 'count', 0)
        && libraryPlaylists.context
        && libraryPlaylists.sorting === sorting) {
        return libraryPlaylists
      }
      if (page * pageSize <= Lget(libraryPlaylists, 'rows.length', 0)
      && libraryPlaylists.context
      && libraryPlaylists.sorting === sorting) {
        return libraryPlaylists
      }
    }

    try {
      // Fetch
      let apiCall = 'likedPlaylists'
      if (context === 'all') {
        apiCall = 'allPlaylists'
      }
      const res = await API.playlists[apiCall]({
        startAt: Lget(libraryPlaylists, 'rows.length', 0) + (Lget(libraryPlaylists, 'rows.length', 0) > 0 ? 1 : 0),
        fetchSize: pageSize,
        filter,
        sorting,
        browser
      })
      libraryPlaylists.browser = browser
      libraryPlaylists.context = context
      libraryPlaylists.sorting = sorting
      libraryPlaylists.filter = filter
      libraryPlaylists.count = res.data.count
      libraryPlaylists.rows = Lconcat(LcloneDeep(Lget(libraryPlaylists, 'rows', [])), res.data.rows)
      await commit('SET_LIBRARY_PLAYLISTS', libraryPlaylists)

      return libraryPlaylists
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('playlist store - getLibraryPlaylists failed' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-load'))
      return {}
    }
  }

}

export default actions
