const Lmerge = require('lodash/merge')
const LfindIndex = require('lodash/findIndex')
const Lget = require('lodash/get')

const mutations = {
  SET_LIBRARY_ARTISTS(state, items) {
    state.libraryArtists = items
  },
  SET_ARTIST(state, artist) {
    state.artist = artist
  },
  UPDATE_ARTIST(state, data) {
    const mergedData = Lmerge(JSON.parse(JSON.stringify(state.artist)), data)
    state.artist = mergedData
  },
  LIKE_POPULAR_SONG(state, data) {
    const songIdx = LfindIndex(Lget(state, 'artist.popularSongs', []), ['id', data.id])
    if (songIdx > -1) {
      const mergedData = JSON.parse(JSON.stringify(state.artist))
      mergedData.popularSongs[songIdx].likes = data.like
      state.artist = mergedData
    }
  },
  PLAY_POPULAR_SONG(state, data) {
    const songIdx = LfindIndex(Lget(state, 'artist.popularSongs', []), ['id', data.id])
    if (songIdx > -1) {
      const mergedData = JSON.parse(JSON.stringify(state.artist))
      mergedData.popularSongs[songIdx].timesPlayed += 1
      state.artist = mergedData
    }
  }
}

export default mutations
