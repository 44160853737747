import LsumBy from 'lodash/sumBy'

const SongMixin = {

  methods: {
    numTracks(songs = []) {
      return Array.isArray(songs) ? `${songs.length} ${this.$t('album.tracks')}` : `${songs} ${this.$t('album.tracks')}`
    },

    playLength(songs = []) {
      let hour = 0
      let min = 0
      let sec = 0
      try {
        const tSec = (LsumBy(songs, 'mp3Minutes') * 60) + LsumBy(songs, 'mp3Seconds')
        min = Math.floor(tSec / 60)
        if (min > 60) {
          hour = Math.floor(min / 60)
          min -= hour * 60
        }
        sec = tSec - (hour * 60 * 60) - (min * 60)
      } catch (e) {
        // Nada
      }
      return hour > 0
        ? `${hour} ${this.$t('track.hour')} ${min} ${this.$t('track.min')} ${sec} ${this.$t('track.sec')}`
        : `${min} ${this.$t('track.min')} ${sec} ${this.$t('track.sec')}`
    }
  }
}

export default SongMixin
