<template>
  <v-container class="large-player">
    <v-row justify="space-between">

      <v-col md="4" class="ma-0 py-0">
        <v-card class="d-flex flex-row" flat tile height="60">
          <AlbumArt :size="60" v-if="!albumArtShown" transition="slide-x-transition" />
          <v-card tile class="mt-n2">
            <v-card-text>
              <div class="subtitle-1 mb-n1 text-truncate">{{ currentSong.songName }}</div>
              <div class="subtitle-2 text-truncate obscured-text"><router-link :to="{ name: 'Artist', params: {id: Lget(currentSong, 'album.artist.id', 0)} }">{{ Lget(currentSong, 'album.artist.artistName', '') }}</router-link></div>
            </v-card-text>
          </v-card>
          <v-card tile class="justify-center">
            <v-card-text class="text-center mt-n1" v-if="currentSong.id">
              <LikeIcon :id="currentSong.id" type="Song" :likes="currentSong.likes"></LikeIcon>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>

      <v-col md="4" class="ma-0 py-0">
        <v-card flat tile height="60">
          <v-card class="d-flex justify-center mb-2">
            <v-btn icon class="mx-1" @click="toggleShuffle">
              <v-icon v-if="shuffle" small>{{ mdiShuffle }}</v-icon>
              <v-icon v-else small>{{ mdiShuffleDisabled }}</v-icon>
            </v-btn>
            <v-btn icon :disabled="!hasQueue" class="mx-1" @click="$emit('skipTrack', 'prev')">
              <v-icon size="28">{{ mdiSkipPrevious }}</v-icon>
            </v-btn>
            <v-btn icon :disabled="!hasQueue" class="mx-1" @click="$emit('togglePlay')">
              <v-icon v-if="isPlaying" size="40">{{ mdiPauseCircle }}</v-icon>
              <v-icon v-else size="40">{{ mdiPlayCircle }}</v-icon>
            </v-btn>
            <v-btn icon :disabled="!hasQueue" class="mx-1" @click="$emit('skipTrack', 'next')">
              <v-icon size="28">{{ mdiSkipNext }}</v-icon>
            </v-btn>
            <v-btn icon class="mx-1" @click="toggleLoop">
              <v-icon v-if="loop" small>{{ mdiRepeat }}</v-icon>
              <v-icon v-else small>{{ mdiRepeatOff }}</v-icon>
            </v-btn>
          </v-card>

          <v-row justify="space-between" class="mt-n4">
            <v-col md="2">
              <div class="text-right text-caption">
                {{ trackSeekPosition }}
              </div>
            </v-col>
            <v-col md="8">
              <DurationSlider class="mt-1"/>
            </v-col>
            <v-col md="2">
              <div class="text-caption">
                {{ trackDuration }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col md="4" class="ma-0 py-0">
        <v-card class="d-flex flex-row justify-end pr-4 pt-1" flat tile height="60">
          <SA size="player"/>
          <v-spacer></v-spacer>
          <v-card>
            <v-btn icon class="mx-1" :to="{name: 'Queue'}">
              <v-icon small>{{ mdiPlaylistPlay }}</v-icon>
            </v-btn>
          </v-card>
          <v-card class="d-flex flex-row">
            <v-btn icon class="ml-1" @click="toggleMute">
              <template>
                <v-icon small v-if="volume >= 70">{{ mdiVolumeHigh }}</v-icon>
                <v-icon small v-else-if="volume > 30">{{ mdiVolumeMedium }}</v-icon>
                <v-icon small v-else-if="volume > 0">{{ mdiVolumeLow }}</v-icon>
                <v-icon small v-else>{{ mdiVolumeMute }}</v-icon>
              </template>
            </v-btn>
            <v-slider hide-details :value="volume" min="0" max="100" class="volume-slider" step="1" @input="updateVolume"></v-slider>
            <v-btn icon class="ml-1" @click="toggleFullscreen()"><v-icon small>{{ mdiArrowExpand }}</v-icon></v-btn>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiPlayCircle, mdiPauseCircle, mdiSkipPrevious, mdiSkipNext,
        mdiShuffle, mdiRepeat, mdiRepeatOff,mdiRepeatOnce,
        mdiPlaylistPlay, mdiVolumeHigh, mdiVolumeMedium, mdiVolumeLow, mdiVolumeMute,
        mdiShuffleDisabled, mdiArrowExpand} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import LikeIcon from '@/components/LikeIcon.vue'
import DurationSlider from '@/components/DurationSlider.vue'
import AlbumArt from '@/components/AlbumArt.vue'
import SA from '@/components/SpectrumAnalyzer'

export default {
  data: () => ({
    mdiArrowExpand,
    mdiPlayCircle,
    mdiPauseCircle,
    mdiSkipPrevious,
    mdiSkipNext,
    mdiShuffle,
    mdiRepeat,
    mdiRepeatOff,
    mdiRepeatOnce,
    mdiPlaylistPlay,
    mdiVolumeHigh,
    mdiVolumeMedium,
    mdiVolumeLow,
    mdiVolumeMute,
    mdiShuffleDisabled
  }),

  components: {
    LikeIcon,
    DurationSlider,
    SA,
    AlbumArt
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'progress', 'currentSong', 'volume', 'albumArtShown', 'isMuted', 'shuffle', 'loop', 'hasQueue']),

    albumArt() {
      return Lget(this, 'currentSong.album.imageSrc', require("@/assets/images/album.png"))
    },
    trackSeekPosition () {
      const duration = Lget(this, 'currentSong.duration', 0)
      return this.progress ? this.$options.filters.minutes((duration/100)*this.progress) : ""
    },
    trackDuration () {
      const duration = Lget(this, 'currentSong.duration', 0)
      return duration ? this.$options.filters.minutes(duration) : ""
    },

  },

  methods: {
    ...mapActions('player', ['setAlbumArtShown', 'toggleMute', 'setVolume', 'toggleShuffle', 'toggleLoop', 'toggleFullscreen']),

    Lget: Lget,

    updateVolume(vol) {
      this.setVolume(vol)
    }
  }
}
</script>

<style scoped>
  .large-player {
    height: 60px !important;
    margin: 20px 0px 10px 0px !important;
    padding: 0px 10px 0px 10px !important;
    max-width: 100% !important;
  }

  .large-player .container {
    max-width: 100% !important;
  }
  .volume-slider {
    width: 120px;
    margin-top: 2px;
  }
  .v-card {
    background-color: var(--v-playerbackground-base) !important;
  }
</style>