import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/album/:id',
    name: 'Album',
    props: true,
    component: () => import(/* webpackChunkName: "album" */ '../views/Album.vue')
  },
  {
    path: '/artist/:id',
    name: 'Artist',
    component: () => import(/* webpackChunkName: "artist" */ '../views/Artist.vue'),
    props: true
  },
  {
    path: '/playlist/:id',
    name: 'Playlist',
    component: () => import(/* webpackChunkName: "album" */ '../views/Playlist.vue'),
    props: true
  },
  {
    path: '/queue',
    name: 'Queue',
    component: () => import(/* webpackChunkName: "artist" */ '../views/Queue.vue')
  },
  {
    path: '/songs',
    name: 'LikedSongs',
    component: () => import(/* webpackChunkName: "artist" */ '../views/Songs.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue'),
    props: true
  },
  {
    path: '/library',
    name: 'Library',
    component: () => import(/* webpackChunkName: "about" */ '../views/Library.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/genre/:genre',
    name: 'Genre',
    props: true,
    component: () => import(/* webpackChunkName: "genre" */ '../views/Genre.vue')
  }
]

export default routes
