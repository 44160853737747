<template>
  <v-card justify-center align-center tile :height="size" :width="size" class="ma-0 pa-0"  @mouseover="hover = true" @mouseleave="hover = null">
    <v-img contain height="100%" witdth="100%" :src="albumArt" @click="openSource"/>
    <v-btn v-show="hover" fab absolute x-small top right :class="{
      'mt-6 mr-n2': albumArtShown,
      'mt-5 mr-n3': !albumArtShown,
      }" @click="setAlbumArtShown(!albumArtShown)" transition="fade-transition">
      <v-icon>{{ albumArtShown ? mdiChevronDown : mdiChevronUp }}</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Lget from 'lodash/get'

export default {
  props: {
    size: {
      type: Number,
      default: 256
    }
  },

  data: () => ({
    hover: false,
    mdiChevronDown,
    mdiChevronUp
  }),

  computed: {
    ...mapGetters('player', ['currentSong', 'albumArtShown']),

    albumArt() {
      return Lget(this, 'currentSong.album.imageSrc', require("@/assets/images/album.png"))
    }
  },

  methods: {
    ...mapActions('player', ['setAlbumArtShown']),

    openSource() {
      const route = Lget(this, 'currentSong.source.route', null)
      if (route) {
        this.$nextTick(() => {
          this.$router.push(route).catch(err => {})
        })
      }
    }
  }
}
</script>

<style scoped>
.v-btn {
  background-color: rgb(0,0,0,0.5) !important;
}

</style>