<template>
  <v-toolbar dense flat>
    <v-spacer></v-spacer>
    <v-btn class="mr-8" icon exact :to="{name: 'Home'}" :aria-label="$t('home.home')">
      <v-icon>{{ mdiHomeOutline }}</v-icon>
    </v-btn>
    <v-btn icon exact :to="{name: 'Search'}" :aria-label="$t('search.search')">
      <v-icon>{{ mdiMagnify }}</v-icon>
    </v-btn>
    <v-btn class="ml-8" icon exact :to="{name: 'Library'}" :aria-label="$t('library.library')">
      <v-icon>{{ mdiBookshelf }}</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
  </v-toolbar>
</template>

<script>
import { mdiMagnify, mdiHomeOutline, mdiBookshelf } from '@mdi/js'

export default {
  data: () => ({
    mdiMagnify,
    mdiHomeOutline,
    mdiBookshelf
  })
}
</script>

<style scoped>
</style>
