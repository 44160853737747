<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small rounded exact v-bind="attrs" v-on="on" :aria-label="$t('user.menu')">
        <v-icon>{{ mdiAccount }}</v-icon>
        <span v-if="!$vuetify.breakpoint.mobile">{{ Lget(user, 'userName', '') }}</span>
        <v-icon v-if="attrs['aria-expanded']==='false'">{{ mdiMenuDown }}</v-icon>
        <v-icon v-else>{{ mdiMenuUp }}</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item exact :to="{name: 'Account'}" :aria-label="$t('menu.account')">
        <v-list-item-title>{{ $t('menu.account') }}</v-list-item-title>
      </v-list-item>
      <v-list-item exact :to="{name: 'About'}" :aria-label="$t('menu.about')">
        <v-list-item-title>{{ $t('menu.about') }}</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item exact @click="appLogout" :aria-label="$t('menu.logout')">
        <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiAccount, mdiMenuDown, mdiMenuUp } from '@mdi/js'
import Lget from 'lodash/get'

export default {
  data: () => ({
    mdiAccount,
    mdiMenuDown,
    mdiMenuUp
  }),

  computed: {
    ...mapGetters('users', ['user'])
  },

  methods: {
    ...mapActions('users', ['logout']),

    Lget: Lget,

    async appLogout() {
      await this.logout()
      this.$nextTick(() => { this.$router.go('/login'); })
    },
  }
}
</script>

<style scoped>
.v-menu__content {
  padding-top: 6px;
}
</style>
