<template>
  <v-container fill-height class="fullscreen" :style="`background-color: ${gradientColor} !important`" @mousemove="mouseEvent">
    <v-layout column class="my-8 px-12 fullscreen-player">
      
      <v-card class="d-flex flex-row title-row" flat tile max-height="60">
        <v-img
          alt="MuCo Logo"
          contain
          src="@/assets/images/logo.png"
          transition="scale-transition"
          max-width="60"
          class="faded-logo"
        />
        <v-card tile class="mt-n2">
          <v-card-text>
            <div class="text-uppercase faded font-weight-medium">{{ $t('player.now-playing') }}</div>
            <div class="body-2 text-truncate">{{ Lget(currentSong, 'source.displayName', '') }}</div>
          </v-card-text>
        </v-card>
      </v-card>
      <FSV class="fsv" v-if="showFSV"/>
      <v-card class="d-flex flex-row album-row align-end" flat tile>
        <v-card tile class="album-art-cnt" transition="fade-transition">
          <v-img contain class="album-art" :src="albumArt"/>
        </v-card>
        <v-card tile class="mt-n2">
          <v-card-text class="pb-0">
            <div class="titleText text-truncate">{{ currentSong.songName }}</div>
            <div class="body-2 text-truncate">{{ Lget(currentSong, 'album.artist.artistName', '') }}</div>
          </v-card-text>
        </v-card>
      </v-card>

      <transition name="slow-fade">
        <v-card height="60" class="d-flex justify-space-between mt-8" v-if="showControls">
          <div class="body-2 text-right">
            {{ trackSeekPosition }}
          </div>
          <DurationSlider :big="true" class="mt-1"/>
          <div class="body-2">
            {{ trackDuration }}
          </div>
        </v-card>
      </transition>

      <transition name="slow-fade">
        <v-card class="d-flex justify-space-between" v-if="showControls">
          <v-card min-width="200">
            <v-btn icon class="mx-1" @click="showFSV = ! showFSV">
              <v-icon>{{ mdiPartyPopper }}</v-icon>
            </v-btn>
          </v-card>

          <v-card>
            <v-card flat tile height="60">
              <v-card class="d-flex justify-center mb-2">
                <v-btn icon class="mx-1" @click="toggleShuffle">
                  <v-icon v-if="shuffle">{{ mdiShuffle }}</v-icon>
                  <v-icon v-else>{{ mdiShuffleDisabled }}</v-icon>
                </v-btn>
                <v-btn icon :disabled="!hasQueue" class="mx-6" @click="$emit('skipTrack', 'prev')">
                  <v-icon size="50">{{ mdiSkipPrevious }}</v-icon>
                </v-btn>
                <v-btn icon :disabled="!hasQueue" class="mx-6" @click="$emit('togglePlay')">
                  <v-icon v-if="isPlaying" size="80">{{ mdiPauseCircle }}</v-icon>
                  <v-icon v-else size="80">{{ mdiPlayCircle }}</v-icon>
                </v-btn>
                <v-btn icon :disabled="!hasQueue" class="mx-6" @click="$emit('skipTrack', 'next')">
                  <v-icon size="50">{{ mdiSkipNext }}</v-icon>
                </v-btn>
                <v-btn icon class="mx-1" @click="toggleLoop">
                  <v-icon v-if="loop">{{ mdiRepeat }}</v-icon>
                  <v-icon v-else>{{ mdiRepeatOff }}</v-icon>
                </v-btn>
              </v-card>
            </v-card>
          </v-card>
          <v-card>
            <v-card class="d-flex flex-row" min-width="200">
              <v-btn icon class="ml-1" @click="toggleMute">
                <template>
                  <v-icon v-if="volume >= 70">{{ mdiVolumeHigh }}</v-icon>
                  <v-icon v-else-if="volume > 30">{{ mdiVolumeMedium }}</v-icon>
                  <v-icon v-else-if="volume > 0">{{ mdiVolumeLow }}</v-icon>
                  <v-icon v-else>{{ mdiVolumeMute }}</v-icon>
                </template>
              </v-btn>
              <v-slider hide-details :value="volume" min="0" max="100" class="volume-slider" step="1" @input="updateVolume"></v-slider>

            <v-btn icon @click="toggleFullscreen()"><v-icon>{{ mdiArrowExpand }}</v-icon></v-btn>
            </v-card>
          </v-card>
        </v-card>
      </transition>

    </v-layout>
  </v-container>
</template>

<script>
import { mdiPlayCircle, mdiPauseCircle, mdiSkipPrevious, mdiSkipNext,
        mdiShuffle, mdiRepeat, mdiRepeatOff,mdiRepeatOnce,
        mdiPlaylistPlay, mdiVolumeHigh, mdiVolumeMedium, mdiVolumeLow, mdiVolumeMute,
        mdiShuffleDisabled, mdiArrowExpand, mdiPartyPopper} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import DurationSlider from '@/components/DurationSlider.vue'
import Lget from 'lodash/get'
import FSV from '@/components/FullscreenVisualization.vue'
import { VibrantMixin } from '@/mixins'

export default {
  data: () => ({
    mdiArrowExpand,
    mdiPlayCircle,
    mdiPauseCircle,
    mdiSkipPrevious,
    mdiSkipNext,
    mdiShuffle,
    mdiRepeat,
    mdiRepeatOff,
    mdiRepeatOnce,
    mdiPlaylistPlay,
    mdiVolumeHigh,
    mdiVolumeMedium,
    mdiVolumeLow,
    mdiVolumeMute,
    mdiShuffleDisabled,
    mdiPartyPopper,
    showFSV: false,
    showControls: false,
    showTimeout: null
  }),

  components: {
    DurationSlider,
    FSV
  },

  mixins: [
    VibrantMixin
  ],

  created() {
    this.mouseEvent()
  },

  mounted () {
    document.body.classList.add('nobars')
  },

  destroyed () {
    document.body.classList.remove('nobars')
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'progress', 'currentSong', 'volume', 'albumArtShown', 'isMuted', 'shuffle', 'loop', 'hasQueue']),

    albumArt() {
      return Lget(this, 'currentSong.album.imageSrc', require("@/assets/images/album.png"))
    },

    trackSeekPosition () {
      const duration = Lget(this, 'currentSong.duration', 0)
      return this.progress ? this.$options.filters.minutes((duration/100)*this.progress) : ""
    },
    trackDuration () {
      const duration = Lget(this, 'currentSong.duration', 0)
      return duration ? this.$options.filters.minutes(duration) : ""
    },

  },

  methods: {
    ...mapActions('player', ['setAlbumArtShown', 'toggleMute', 'setVolume', 'toggleShuffle', 'toggleLoop', 'toggleFullscreen']),

    Lget: Lget,

    updateVolume(vol) {
      this.setVolume(vol)
    },

    mouseEvent(event) {
      const me = this
      this.showControls = true
      if (this.showTimeout) {
        clearTimeout(this.showTimeout)
      }
      this.showTimeout = setTimeout(() => {
        me.showControls = false
      }, 3000)
    }
  }
}
</script>

<style>
body.nobars {
  overflow: hidden;
}
body.nobars::-webkit-scrollbar {
  display: none;
}
</style>

<style scoped>
.fsv {
  position: absolute;
  top: 60px;
  left: 50vw;
}
.container {
  max-width: 100vw !important;
}
.v-card {
  background-color: transparent !important;
}
.fullscreen {
  height: 100vh;
  width: 100vw;
  z-index: 10;
}
.faded-logo {
  opacity: 0.5;
}
.faded {
  opacity: 0.5;
}
.album-row {
  margin-top: 30vh;
}
.title-row {
  height: 10vh;
}
.album-art {
  filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.7));
  height: 25vh;
  width: 25vh;
}
.titleTextCnt {
  min-height: 80px;
}
.titleText {
  font-weight: 300;
  letter-spacing: initial !important;
  font-family: "Anton", sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 6rem !important;
  line-height: 6.5rem;
  padding-top: 1rem;
}
.slow-fade-enter-active {
  transition: all 1s ease;
}
.slow-fade-leave-active {
  transition: all 2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slow-fade-enter, .slow-fade-leave-to {
  opacity: 0;
}
</style>