<template>
  <v-container class="player-container" :class="{'fullscreen': isFullscreen && !$vuetify.breakpoint.mobile}">
    <SmallPlayer v-if="$vuetify.breakpoint.mobile" 
      @togglePlay="togglePlay"
    />
    <template v-else>
      <FullscreenPlayer v-if="isFullscreen"
        transition="fade-transition"
        @togglePlay="togglePlay"
        @skipTrack="skipTrack(...arguments)"
      />
      <LargePlayer v-else
        @togglePlay="togglePlay"
        @skipTrack="skipTrack(...arguments)"
      />
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import SmallPlayer from '@/components/SmallPlayer.vue'
import LargePlayer from '@/components/LargePlayer.vue'
import FullscreenPlayer from '@/components/FullscreenPlayer.vue'

export default {
  components: {
    SmallPlayer,
    LargePlayer,
    FullscreenPlayer
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'progress', 'currentSong', 'volume', 'isFullscreen']),

    albumArt() {
      return Lget(this, 'currentSong.album.imageSrc', require("@/assets/images/album.png"))
    }
  },

  methods: {
    ...mapActions('player', ['play', 'pause', 'playNext', 'playPrev']),

    Lget: Lget,

    togglePlay() {
      if (!this.currentSong) return
      this.isPlaying ? this.pause() : this.play()
    },

    skipTrack(direction) {
      if (direction==="next") {
        this.playNext()
      } else  {
        this.playPrev()
      }
    },
  }
}
</script>

<style scoped>
  .player-container {
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .player-container.fullscreen {
    background-color: var(--v-playerbackground-base);
  }

</style>