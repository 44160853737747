import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const playlistModule = {
  namespaced: true,
  state: {
    pageSize: 50,
    libraryPlaylists: {
      dirty: false, browser: '', context: 'liked', sorting: 'playlist', filter: '', rows: [], count: 0
    },
    playlists: [],
    playlist: {}
  },
  getters,
  actions,
  mutations
}

export default playlistModule
