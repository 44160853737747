<template>
  <div class="tiles">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as d3 from 'd3';

export default {

  data: () => ({
  }),

  created() {
    this.start()
  },

  watch: {
    isPlaying: function (newVal, oldVal) {
      if (newVal)
        this.start()
      else
        this.stop()
    }
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'analyzer']),
  },

  methods: {

    stop() {
    },

    start() {
      if (! this.analyzer) { return }

      // Local copy
      const analyzer = this.analyzer

      // Base rotation start
      let rotateIndex = 0

      function renderFrame() {
        // Grab data fron analyzer and use 64 first values
        let data = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(data);
        data = data.slice(0,64)

        // Rotate a bit
        rotateIndex += .2;

        d3.select('.tiles')
          .style('transform', function() {
            // Rotate base div a bit for each frame, keep perspecive
            return 'perspective(1000px) rotateX(66deg) rotateZ(' + rotateIndex + 'deg)';
          })
          .selectAll('div')
          .data(data)
          .style('transform', function(h) {
            // Move each visualization tile up in Z. Higher data values moves tile highter
            return 'translateZ(' + ((h / 255) * 100) + 'px)';
          })
          .style('opacity', function(o){
            // Higher data makes tile less opaque
            return (o / 255);
          })
          .style('background', function(o){
            // Higher data makes tile more red, lower makes it more green
            return `rgba(${o}, ${255-o}, 0, 0.6)`;
          })
          .enter()
          .append('div')

        requestAnimationFrame(renderFrame)
      }
      renderFrame()
    }
  }
}
</script>

<style>
/* As tiles are dynamically added, their style needs to be unscoped */
.tiles div {
  float: left;
  background: rgba(255, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  box-shadow: 0px 00px 10px rgba(0, 0, 0, 0.6) inset;
}
</style>

<style scoped>
.tiles {
  background: transparent;
  border: 1px dashed rgba(255, 255, 255, 0.4);
  width: 360px;
  margin: 4% auto 0 auto;
  transform-style: preserve-3d;
}

.tiles::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
</style>