<template>
  <v-container class="small-player">
    <!-- The Smaller player -->
    <v-row no-gutters align-center justify-center>
      <v-col cols="2" class="mt-1">
        <v-img contain height="40" witdth="40" :src="albumArt" @click="openQueue" />
      </v-col>
      <v-col cols="7">
        <div class="subtitle-1 mb-n1 text-truncate">{{ currentSong.songName }}</div>
        <div class="body-2 text-truncate">{{ Lget(currentSong, 'album.artist.artistName', '') }}</div>
      </v-col>
      <v-col cols="3">
        <v-layout fill-height align-center justify-end>
          <LikeIcon :id="currentSong.id" type="Song" :likes="currentSong.likes"></LikeIcon>
          <v-btn icon @click="$emit('togglePlay')" class="mr-3">
            <v-icon v-if="isPlaying" size="32">{{ mdiPause }}</v-icon>
            <v-icon v-else size="32">{{ mdiPlay }}</v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <DurationSlider />
  </v-container>
</template>

<script>
import { mdiPlay, mdiPause } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import LikeIcon from '@/components/LikeIcon.vue'
import DurationSlider from '@/components/DurationSlider.vue'

export default {
  data: () => ({
    mdiPlay,
    mdiPause
  }),

  components: {
    LikeIcon,
    DurationSlider
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'progress', 'currentSong']),

    albumArt() {
      return Lget(this, 'currentSong.album.imageSrc', require("@/assets/images/album.png"))
    }
  },

  methods: {
    Lget: Lget,

    openQueue() {
      this.$nextTick(() => {
        this.$router.push({ name: 'Queue' } ).catch(err => {})
      })
    }

  }
}
</script>

<style scoped>
.small-player {
  height: 50px !important;
  margin: 0px !important;
  padding: 0px !important;
  max-width: 100% !important;
}
</style>