import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const playerModule = {
  namespaced: true,
  state: {
    howl: null,
    analyzer: null,
    seek: 0,
    position: 0,
    intervalFn: null,
    track: {},
    queue: [],
    queueIdx: 0,
    seeking: false,

    loop: false,
    shuffle: false,
    volume: 0.75,
    muted: false,
    beforeMutedVolume: 0.75,

    albumArtShown: false,

    fullscreen: false
  },
  getters,
  actions,
  mutations
}

export default playerModule
