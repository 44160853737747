import Vue from 'vue'
import API from '../../plugins/api'
import i18n from '../../plugins/i18n'

const actions = {
  setSearchResult({ commit }, result) {
    Vue?.$log?.info('search store - setSearchResult')
    commit('SET_RESULT', result)
  },

  setSearchFilter({ commit }, filter) {
    Vue?.$log?.info('search store - setSearchFilter')
    commit('SET_FILTER', filter)
  },

  setSearchCriteria({ commit }, criteria) {
    Vue?.$log?.info('search store - setSearchCriteria')
    commit('SET_CRITERIA', criteria)
  },

  async getSearchHistory({ commit }) {
    Vue?.$log?.info('search store - getSearchHistory')
    try {
      const list = await this.getters['search/searchHistory']

      if (list && list.length) {
        return
      }

      const res = await API.search.userHistory()
      commit('SET_HISTORY', res.data.map((i) => i.itemData))
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('search store - getSearchHistory failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-load'))
    }
  },

  async clearSearchHistory({ commit }) {
    Vue?.$log?.info('search store - clearSearchHistory')
    try {
      await API.search.clearUserHistory()
      commit('SET_HISTORY', [])
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('search store - clearSearchHistory failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-delete'))
    }
  },

  async addToSearchHistory({ commit }, item) {
    Vue?.$log?.info('search store - addToSearchHistory')
    try {
      await API.search.addToUserHistory({ item })
      commit('ADD_TO_HISTORY', item)
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('search store - addToSearchHistory failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-add'))
    }
  },

  async removeFromSearchHistory({ commit }, item) {
    Vue?.$log?.info('search store - removeFromSearchHistory')
    try {
      await API.search.removeFromUserHistory({ data: item })
      commit('REMOVE_HISTORY_ITEM', item)
    } catch (err) {
      // BUG in babel-eslint https://github.com/babel/babel/issues/10904
      // eslint-disable-next-line prefer-template
      Vue?.$log?.error('search store - removeFromSearchHistory failed:' + err)
      Vue?.prototype?.$events?.fire('showError', i18n.t('errors.failed-delete'))
    }
  }

}

export default actions
