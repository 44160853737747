<template>
  <v-container class="ma-0 pa-0 nav-buttons">
    <v-btn :class="{ 'shaded-btn': shaded }" icon @click="$router.back()" :disabled="!$router.canGoBack()" :aria-label="$t('buttons.back')">
      <v-icon>{{ mdiChevronLeft }}</v-icon>
    </v-btn>
    <v-btn icon class="ml-3" :class="{ 'shaded-btn': shaded }" @click="$router.forward()" v-if="!$vuetify.breakpoint.mobile" :disabled="!$router.canGoForward()"  :aria-label="$t('buttons.forward')">
      <v-icon>{{ mdiChevronRight }}</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  props: {
    shaded: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiChevronLeft,
    mdiChevronRight,
  })
}
</script>

<style scoped>
.nav-buttons {
  max-width: 80px !important;
}
.v-btn {
  height: 32px !important;
  width: 32px !important;
}
.v-btn.shaded-btn {
  background-color: rgb(0,0,0,0.25);
}
</style>