import Lget from 'lodash/get'

const getters = {
  progress: (state) => (state.position * 100),
  intervalFn: (state) => (state.intervalFn),
  howl: (state) => (state.howl),
  analyzer: (state) => (state.analyzer),
  currentSong: (state) => (state.track),
  currentSongId: (state) => (Lget(state, 'track.id', null)),
  isPlaying: (state) => (state?.howl?.playing()),
  hasQueue: (state) => (Boolean(Lget(state, 'queue.length', 0))),
  queue: (state) => (state.queue),
  queueIdx: (state) => (state.queueIdx),
  volume: (state) => (state.volume * 100),
  albumArtShown: (state) => (state.albumArtShown),
  isMuted: (state) => (state.muted),
  shuffle: (state) => (state.shuffle),
  loop: (state) => (state.loop),
  isSeeking: (state) => (state.seeking),
  isFullscreen: (state) => (state.fullscreen)
}

export default getters
