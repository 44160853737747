import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const artistsModule = {
  namespaced: true,
  state: {
    pageSize: 50,
    artist: {},
    libraryArtists: {
      dirty: false, browser: '', context: 'liked', sorting: 'artist', filter: '', rows: [], count: 0
    }
  },
  getters,
  actions,
  mutations
}

export default artistsModule
