import Vue from 'vue'
import API from '../../plugins/api'

const actions = {
  async loadChangelog({ commit }) {
    Vue?.$log?.info('system store - loadChangelog')

    let changelog = await this.getters['system/changelog']
    if (changelog) {
      return changelog
    }

    try {
      const res = await API.system.changelog()
      changelog = res.data
    } catch (error) {
      changelog = 'No news'
    }

    await commit('SET_CHANGELOG', changelog)
    return changelog
  }
}

export default actions
