import Vue from 'vue'
import Lget from 'lodash/get'
import i18n from './i18n'

const axios = require('axios')

const API = {

  baseUrl: process.env.VUE_APP_API_BASE_URL,
  client: null,

  init(options) {
    Vue?.$log?.info('API - init')
    return new Promise((resolve, reject) => {
      // Axios instance
      this.client = axios.create({
        baseURL: this.baseUrl,
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          // eslint-disable-next-line comma-dangle
          'Content-Type': 'application/json'
        }
      });

      // Timeout
      this.client.defaults.timeout = 60000

      const errorHandler = async (error) => {
        const originalConfig = error.config;

        if (originalConfig.url !== '/api/v1/auth/signin' && error.response) {
          // Access Token was expired
          // eslint-disable-next-line no-underscore-dangle
          if (Lget(error, 'response.status', -1) === 401 && !originalConfig._retry) {
            // eslint-disable-next-line no-underscore-dangle
            originalConfig._retry = true;
            try {
              const userData = JSON.parse(localStorage.getItem('user'))
              if (userData && userData.refreshToken) {
                const response = await API.auth.refreshToken({ refreshToken: userData.refreshToken })
                  .catch((err) => Promise.reject(new Error({ ...err })))

                // Retry previous call
                if (response && response.data) {
                  return API.client(error.config)
                }
              }
            } catch (e) {
              Vue?.$log?.fatal('Failed to refresh access token:', e)
            }
          }
        }
        if (Lget(error, 'response.status', -1) === 403 && Lget(error, 'config.url', '') === '/api/v1/auth/refresh-token') {
          // Token expired
          try {
            options.store.dispatch('users/logout')
              .catch(() => {})
              .finally(() => {
                options.router.push('/login').catch((() => {}))
                Vue.prototype.$dialog.error({
                  text: i18n.t('errors.session-expire-text'),
                  title: i18n.t('errors.session-expire-title')
                })
              })
          } catch (e) {
            Vue?.$log?.fatal('Failed to handle session end:', e)
          }
        }
        return Promise.reject(new Error(error))
      }

      // Handle responses
      const successHandler = (response) => response

      this.client.interceptors.response.use(
        (response) => successHandler(response),
        (error) => errorHandler(error)
      );

      // Return client or Error
      if (this.client) {
        resolve(this.client)
      } else {
        reject(new Error(i18n.t('errors.failed-api-init')))
      }
    });
  },

  // Housekeeping
  setHeaders(key, val) {
    this.client.defaults.headers.common[key] = val
  },
  deleteHeaders(key) {
    delete this.client.defaults.headers.common[key]
  },

  // Auth
  auth: {
    login(params) {
      Vue?.$log?.info('API - auth.login')
      return API.client.post('/api/v1/auth/signin', params)
    },
    logout() {
      Vue?.$log?.info('API - auth.logout')
      return API.client.delete('/api/v1/auth/signout').catch(() => {})
    },
    refreshToken(params) {
      Vue?.$log?.info('API - auth.refreshToken')
      return API.client.patch('/api/v1/auth/refresh-token', params)
    }
  },

  // System
  system: {
    changelog(params) {
      Vue?.$log?.info('API - system.changelog')
      return API.client.get('/api/v1/system/changelog', params)
    }
  },

  // Users
  users: {
    get(id) {
      Vue?.$log?.info('API - users.get')
      return API.client.get(`/api/v1/users/${id}`)
    },
    update(id, params) {
      Vue?.$log?.info('API - users.update')
      return API.client.patch(`/api/v1/users/${id}`, params)
    }
  },

  // Songs
  songs: {
    queuedSongs(params) {
      Vue?.$log?.info('API - songs.queuedSongs')
      return API.client.patch('/api/v1/songs/queuedsongs', params)
        .then((items) => items)
    },
    likedSongs(params) {
      Vue?.$log?.info('API - songs.likedSongs')
      return API.client.patch('/api/v1/songs/likedsongs', params)
        .then((items) => items)
    },
    like(id, like) {
      Vue?.$log?.info('API - songs.like')
      return API.client.patch(`/api/v1/songs/${id}/like`, { data: { like } })
        .then((response) => response.data)
    },
    play(id) {
      Vue?.$log?.info('API - songs.play')
      return API.client.put(`/api/v1/songs/${id}/play`)
        .then((response) => response.data)
    }
  },

  // Artists
  artists: {
    get(id) {
      Vue?.$log?.info('API - artists.get')
      return API.client.get(`/api/v1/artists/${id}`)
        .then((artist) => artist)
    },
    like(id, like) {
      Vue?.$log?.info('API - artist.like')
      return API.client.patch(`/api/v1/artists/${id}/like`, { data: { like } })
        .then((response) => response.data)
    },
    likedArtists(params) {
      Vue?.$log?.info('API - albums.likedArtists')
      return API.client.patch('/api/v1/artists/likedartists', params)
        .then((items) => items)
    },
    allArtists(params) {
      Vue?.$log?.info('API - albums.allArtists')
      return API.client.patch('/api/v1/artists/allartists', params)
        .then((items) => items)
    }
  },

  // Albums
  albums: {
    get(id) {
      Vue?.$log?.info('API - albums.get')
      return API.client.get(`/api/v1/albums/${id}`)
        .then((album) => {
          album.data.songs.forEach((song) => {
            // eslint-disable-next-line no-param-reassign
            song.album = {
              albumName: album.data.albumName, id: album.data.id, uuid: album.data.uuid, imageSrc: album.data.imageSrc
            }
            // eslint-disable-next-line no-param-reassign
            song.album.artist = album.data.artist
          })
          // eslint-disable-next-line no-param-reassign
          album.data.songs = album.data.songs.sort((a, b) => a.trackNum - b.trackNum)
          return album
        })
    },
    like(id, like) {
      Vue?.$log?.info('API - albums.like')
      return API.client.patch(`/api/v1/albums/${id}/like`, { data: { like } })
        .then((response) => response.data)
    },
    getAlbumUrl(uuid, size) {
      Vue?.$log?.info('API - albums.getAlbumUrl')
      return `${API.baseUrl}/api/v1/albums/${uuid}/cover?size=${size}`
    },
    play(id) {
      Vue?.$log?.info('API - albums.play')
      return API.client.put(`/api/v1/albums/${id}/play`)
        .then((response) => response.data)
    },
    userAlbums(params) {
      Vue?.$log?.info('API - albums.userAlbums')
      return API.client.get('/api/v1/albums/useralbums', params)
        .then((items) => items)
    },
    userGenres(params) {
      Vue?.$log?.info('API - albums.userGenres')
      return API.client.get('/api/v1/albums/usergenres', params)
        .then((items) => items)
    },
    likedAlbums(params) {
      Vue?.$log?.info('API - albums.likedAlbums')
      return API.client.patch('/api/v1/albums/likedalbums', params)
        .then((items) => items)
    },
    allAlbums(params) {
      Vue?.$log?.info('API - albums.allAlbums')
      return API.client.patch('/api/v1/albums/allalbums', params)
        .then((items) => items)
    }
  },

  // Playlists
  playlists: {
    list() {
      Vue?.$log?.info('API - playlists.list')
      return API.client.get('/api/v1/playlists')
        .then((playlists) => playlists)
    },
    play(id) {
      Vue?.$log?.info('API - playlist.play')
      return API.client.put(`/api/v1/playlists/${id}/play`)
        .then((response) => response.data)
    },
    get(id) {
      Vue?.$log?.info('API - playlists.get')
      return API.client.get(`/api/v1/playlists/${id}`)
        .then((playlist) => {
          playlist.data.songs.forEach((song) => {
            // eslint-disable-next-line no-param-reassign
            song.artist = song.album.artist
          })
          // eslint-disable-next-line no-param-reassign
          playlist.data.songs = playlist.data.songs.sort((a, b) => a.PlaylistItem.sortOrder - b.PlaylistItem.sortOrder)
          return playlist
        })
    },
    delete(id) {
      Vue?.$log?.info('API - playlists.delete')
      return API.client.delete(`/api/v1/playlists/${id}`)
    },
    like(id, like) {
      Vue?.$log?.info('API - playlists.like')
      return API.client.patch(`/api/v1/playlists/${id}/like`, { data: { like } })
        .then((response) => response.data)
    },
    resort(id, items) {
      Vue?.$log?.info('API - playlists.resort')
      return API.client.patch(`/api/v1/playlists/${id}/resort`, { data: { items } })
        .then((response) => response.data)
    },
    getCover(id) {
      Vue?.$log?.info('API - playlists.getCover')
      return API.client.get(`/api/v1/playlists/${id}/cover`)
        .then((response) => response.data)
    },
    addTrack(id, trackId) {
      Vue?.$log?.info('API - playlists.addTrack')
      return API.client.patch(`/api/v1/playlists/${id}/addTrack`, { data: { trackId } })
        .then((response) => response.data)
    },
    removeTrack(id, itemId) {
      Vue?.$log?.info('API - playlists.removeTrack')
      return API.client.patch(`/api/v1/playlists/${id}/removeTrack`, { data: { itemId } })
        .then((response) => response.data)
    },
    create(editData) {
      Vue?.$log?.info('API - playlists.create')
      return API.client.post('/api/v1/playlists', { data: editData })
        .then((response) => response.data)
    },
    update(id, editData) {
      Vue?.$log?.info('API - playlists.update')
      return API.client.patch(`/api/v1/playlists/${id}`, { data: editData })
        .then((response) => response.data)
    },
    likedPlaylists(params) {
      Vue?.$log?.info('API - playlists.likedPlaylists')
      return API.client.patch('/api/v1/playlists/likedplaylists', params)
        .then((items) => items)
    },
    allPlaylists(params) {
      Vue?.$log?.info('API - playlists.allPlaylists')
      return API.client.patch('/api/v1/playlists/allplaylists', params)
        .then((items) => items)
    }

  },

  // Search interface
  search: {
    all(params) {
      Vue?.$log?.info('API - search.all')
      return API.client.patch('/api/v1/search', params)
    },
    userHistory() {
      Vue?.$log?.info('API - search.userHistory')
      return API.client.get('/api/v1/search/userhistory')
    },
    addToUserHistory(item) {
      Vue?.$log?.info('API - search.addToUserHistory')
      return API.client.patch('/api/v1/search/userhistory', item)
    },
    removeFromUserHistory(item) {
      Vue?.$log?.info('API - search.removeFromUserHistory')
      return API.client.delete('/api/v1/search/userhistory', item)
    },
    clearUserHistory() {
      Vue?.$log?.info('API - search.clearUserHistory')
      return API.client.delete('/api/v1/search/userhistory')
    }
  }
};

export default API
