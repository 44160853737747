<template>
  <v-card elevation="2" class="pa-1">
    <canvas id='canvas' :width="sizes[size].width" :height="sizes[size].height"></canvas>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'

export default {

  props: {
    size: String,
    default: 'large'
  },

  data: () => ({
    resized: null,
    sizes: {
      large: {
        width: 800,
        height: 350,
        meterWidth: 10,
        gap: 2,
        meterNum: null,
        capHeight: 2
      },
      medium: {
        width: 400,
        height: 175,
        meterWidth: 10,
        gap: 2,
        meterNum: null,
        capHeight: 2
      },
      small: {
        width: 200,
        height: 80,
        meterWidth: 5,
        gap: 2,
        meterNum: null,
        capHeight: 2
      },
      tiny: {
        width: 24,
        height: 24,
        meterWidth: 2,
        gap: 1,
        meterNum: 5,
        capHeight: 1
      },
      player: {
        width: 60,
        height: 48,
        meterWidth: 4,
        gap: 1,
        meterNum: null,
        capHeight: 2
      }
    }
  }),

  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  watch: {
    isPlaying: function (newVal, oldVal) {
      if (newVal)
        this.start()
      else
        this.stop()
    },
    resized: function (newVal, oldVal) {
      if (newVal)
        this.stop()
      else
        this.start()
    },
  },

  computed: {
    ...mapGetters('player', ['isPlaying', 'analyzer']),
  },

  methods: {
    onResize () {
      if (typeof window === 'undefined') return
      this.resized = window.innerWidth < this.$vuetify.breakpoint.mobileBreakpoint
    },

    stop() {
    },

    start() {
      if (! this.analyzer) { return }
      if (! this.sizes[this.size]) { return }

      // Local copy
      const analyzer = this.analyzer

      var canvas = document.getElementById('canvas'),
          capHeight = this.sizes[this.size].capHeight || 2,
          cwidth = canvas.width,
          cheight = canvas.height - capHeight,
          meterWidth = this.sizes[this.size].meterWidth || 10,
          gap = this.sizes[this.size].gap || 2,
          capStyle = '#fff',
          meterNum = this.sizes[this.size].meterNum || (cwidth / (meterWidth + gap)),
          capYPositionArray = [];

      var ctx = canvas.getContext('2d');
      var gradient = ctx.createLinearGradient(0, 0, 0, cheight);
      gradient.addColorStop(1, '#0f0');
      gradient.addColorStop(0.5, '#ff0');
      gradient.addColorStop(0, '#f00');

      function renderFrame() {
        var array = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(array);
        var step = Math.round(array.length / meterNum);
        ctx.clearRect(0, 0, cwidth, cheight);
        for (var i = 0; i < meterNum; i++) {
          // array[i * step] is 0-255, scale that to match cheight
          var value = Math.floor(array[i * step] * (cheight/255));

          if (capYPositionArray.length < Math.round(meterNum)) {
            capYPositionArray.push(value);
          };
          ctx.fillStyle = capStyle;

          // Draw the cap, with transition effect
          if (value < capYPositionArray[i]) {
            ctx.fillRect(i * (meterWidth+gap), cheight - (--capYPositionArray[i]), meterWidth, capHeight);
          } else {
            ctx.fillRect(i * (meterWidth+gap), cheight - value, meterWidth, capHeight);
            capYPositionArray[i] = value;
          };
          ctx.fillStyle = gradient;
          ctx.fillRect(i * (meterWidth+gap), cheight - value + capHeight, meterWidth, cheight);
        }
        requestAnimationFrame(renderFrame);
      }
      renderFrame();
    }
  }
}
</script>

<style scoped>
.bar-wrapper {
    height: 300px;
    position: relative;
}
.bar {
    position: relative;
    bottom: 0;
    width: 5px;
    display: inline-block;
    border: 1px solid red;
    height: 5px;
    border-bottom: 3px solid #fff;
}
</style>