// const LfindIndex = require('lodash/findIndex')
// const Lmerge = require('lodash/merge')
const Lget = require('lodash/get')
const Lmap = require('lodash/map')
const Lkeys = require('lodash/keys')
const LpickBy = require('lodash/pickBy')
const LforEach = require('lodash/forEach')
const Lfilter = require('lodash/filter')
const LcloneDeep = require('lodash/cloneDeep')

const mutations = {
  SET_PROGRESS(state, { seek, pos }) {
    state.playing = seek
    state.position = pos
  },
  SET_HOWL(state, howl) {
    state.howl = howl
  },
  SET_ANALYZER(state, analyzer) {
    state.analyzer = analyzer
  },
  SET_INTERVAL_FN(state, fn) {
    state.intervalFn = fn
  },
  SET_TRACK(state, track) {
    state.track = track
  },
  TOGGLE_SHUFFLE(state) {
    state.shuffle = !state.shuffle
  },
  TOGGLE_LOOP(state) {
    state.loop = !state.loop
  },
  SET_VOLUME(state, volume) {
    state.volume = volume
    state.muted = state.volume === 0
    if (state.howl) {
      state.howl.volume(state.volume)
    }
  },
  SET_QUEUE(state, queue) {
    state.queue = LcloneDeep(queue)
    try {
      localStorage.setItem('userQueue', JSON.stringify({
        idx: 0,
        rows: state.queue.map((o, i) => ({ id: o.id, idx: i, source: o.source }))
      }))
    } catch (error) {
      // nada
    }
  },
  SET_SEEKING(state, bool) {
    state.seeking = bool
  },
  TOGGLE_FULLSCREEN(state) {
    state.fullscreen = !state.fullscreen
  },
  SET_POS(state, pos) {
    const track = this.getters['player/currentSong']
    const duration = Lget(track, 'duration', 0)
    const currentPos = state.howl.seek()
    let seek = currentPos
    if (typeof pos === 'string' || pos instanceof String) {
      // Relative seeking +/-
      switch (pos.charAt(0)) {
        case '+':
          seek = Math.min(currentPos + Number(pos.substring(1)), duration)
          break;
        case '-':
          seek = Math.max(currentPos - Number(pos.substring(1)), 0)
          break;
        default:
          seek = currentPos
          break;
      }
    } else {
      seek = duration === 0 ? 0 : duration * (pos / 100)
    }
    state?.howl?.seek(seek)
  },
  ADD_TO_QUEUE(state, items) {
    let queuedItems = JSON.parse(JSON.stringify(items))
    // we need an array
    queuedItems = Array.isArray(queuedItems) ? queuedItems : [queuedItems]
    if (state.queue.length === 0) {
      // new item(s) is queue
      state.queue = queuedItems
    } else {
      // get currently queued where source.isQueued (ie. added by "Add to queue" functions)
      const queued = Lfilter(state.queue.slice(state.queueIdx + 1), (o) => Lget(o, 'source.isQueued', false))
      // new item(s) should be inserted at state.queueIdx + queued.length + 1
      state.queue.splice(state.queueIdx + queued.length + 1, 0, ...queuedItems)
    }
    try {
      localStorage.setItem('userQueue', JSON.stringify({
        idx: state.queueIdx,
        rows: state.queue.map((o, i) => ({ id: o.id, idx: i, source: o.source }))
      }))
    } catch (error) {
      // nada
    }
  },
  SET_QUEUE_IDX(state, queueIdx) {
    state.queueIdx = queueIdx
    try {
      localStorage.setItem('userQueue', JSON.stringify({
        idx: state.queueIdx,
        rows: state.queue.map((o, i) => ({ id: o.id, idx: i, source: o.source }))
      }))
    } catch (error) {
      // nada
    }
  },
  SET_ALBUM_ART_SHOWN(state, show) {
    state.albumArtShown = show
  },
  UPDATE_QUEUE_SONG(state, data) {
    // All queued tracks
    const songIdxs = Lmap(Lkeys(LpickBy(state.queue, { id: data.id })), Number)
    if (songIdxs.length) {
      let mergedData = JSON.parse(JSON.stringify(state.queue))
      mergedData = LforEach(mergedData, (o) => {
        Object.assign(o, { likes: data.like })
        return o;
      });
      state.queue = mergedData
    }
    // Current Track
    if (Lget(state, 'track.id', null) === data.id) {
      state.track.likes = data.like
    }
  },
  TOGGLE_MUTE(state) {
    if (!state.muted) {
      state.beforeMutedVolume = (state.volume === 0 ? 0.1 : state.volume)
    }
    state.muted = !state.muted
    state.volume = state.muted ? 0 : state.beforeMutedVolume
    if (state.howl) {
      state.howl.volume(state.volume)
    }
  }

}

export default mutations
