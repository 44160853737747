import Lget from 'lodash/get'
import Vue from 'vue'
import Lmerge from 'lodash/merge'
import LcloneDeep from 'lodash/cloneDeep'
import Lconcat from 'lodash/concat'
import API from '../../plugins/api'

const actions = {
  async getArtist({ commit }, id) {
    Vue?.$log?.info('artists store - getArtist')

    let artist = this.getters['artists/artist']

    if (artist && artist.id === id) {
      return artist
    }

    const res = await API.artists.get(id)
    artist = Lget(res, 'data', {})
    commit('SET_ARTIST', artist)

    return artist
  },

  // eslint-disable-next-line no-unused-vars
  async likeArtist({ commit }, { id, like }) {
    Vue?.$log?.info('artists store - likeArtist')

    const res = await API.artists.like(id, like)
    commit('UPDATE_ARTIST', { likes: like })
    return res
  },

  async likePopularSong({ commit }, { id, like }) {
    Vue?.$log?.info('artist store - likePopularSong')

    commit('LIKE_POPULAR_SONG', { id, like })
  },

  async playPopularSong({ commit }, { id }) {
    Vue?.$log?.info('artist store - playPopularSong')

    commit('PLAY_POPULAR_SONG', { id })
  },

  async playPopularSongs({ dispatch }, data) {
    Vue?.$log?.info('artist store - playPopularSongs')
    // Add sorted songs with source information
    if (!Lget(data, 'artist.popularSongs', false)) {
      return
    }
    const queue = data.artist.popularSongs.map((s) => Lmerge(
      LcloneDeep(s),
      { source: { isQueued: false, displayName: data.artist.artistName, route: { name: 'Artist', params: { id: data.artist.id } } } }
    ))
    dispatch('player/setQueue', queue, { root: true })
    dispatch('player/setQueueIdx', data.startIdx, { root: true })
    dispatch('player/playThis', null, { root: true })
  },

  async clearLibraryArtists({ commit }, defaults = {}) {
    await commit('SET_LIBRARY_ARTISTS', Lmerge({
      dirty: false, browser: '', context: 'liked', sorting: 'artist', filter: '', rows: [], count: 0
    }, defaults))
  },

  async getLibraryArtists({ commit }, {
    browser, context, page, filter, sorting
  }) {
    const message = `artists store - getLibraryArtists (page: ${page})`
    Vue?.$log?.info(message)
    const pageSize = await this.getters['artists/artistPageSize']
    const libraryArtists = await this.getters['artists/libraryArtists']

    // Got All or thoose we want?
    if (Lget(libraryArtists, 'count', 0) > 0) {
      if (Lget(libraryArtists, 'rows.length', 0) === Lget(libraryArtists, 'count', 0)
        && libraryArtists.context === context
        && libraryArtists.sorting === sorting) {
        return libraryArtists
      }
      if (page * pageSize <= Lget(libraryArtists, 'rows.length', 0)
        && libraryArtists.context === context
        && libraryArtists.sorting === sorting) {
        return libraryArtists
      }
    }

    // Fetch
    let apiCall = 'likedArtists'
    if (context === 'all') {
      apiCall = 'allArtists'
    }
    const res = await API.artists[apiCall]({
      startAt: Lget(libraryArtists, 'rows.length', 0) + (Lget(libraryArtists, 'rows.length', 0) > 0 ? 1 : 0),
      fetchSize: pageSize,
      filter,
      sorting,
      browser
    })
    libraryArtists.browser = browser
    libraryArtists.context = context
    libraryArtists.sorting = sorting
    libraryArtists.filter = filter
    libraryArtists.count = res.data.count
    libraryArtists.rows = Lconcat(LcloneDeep(Lget(libraryArtists, 'rows', [])), res.data.rows)
    await commit('SET_LIBRARY_ARTISTS', libraryArtists)

    return libraryArtists
  }

}

export default actions
