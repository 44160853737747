<template>
  <v-app>
    <template v-if="isLoggedIn">
      <SideNav v-if="!$vuetify.breakpoint.mobile && !isFullscreen" />
    </template>

    <v-main v-show="!isFullscreen || $vuetify.breakpoint.mobile" :class="`${isLoggedIn ? 'main-background' : 'login-background'}`">
      <router-view :key="$route.fullPath"/>
    </v-main>

    <v-footer v-if="isLoggedIn" fixed class="py-0 px-0">
      <transition name="slide">
        <MuCoPlayer v-if="hasQueue"/>
      </transition>
      <FooterNav v-if="$vuetify.breakpoint.mobile"/>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import MuCoPlayer from '@/components/MuCoPlayer'
import FooterNav from '@/components/FooterNav'
import SideNav from '@/components/SideNav.vue'

export default {
  name: 'App',

  metaInfo() {
    return {
      title: this.$t('app.appname'),
      titleTemplate: `%s | ${this.$t('app.appname')} (${this.$t('app.version')})`,
      meta: [{
        property: 'og:site_name',
        content: `${this.$t('app.appname')} (${this.$t('app.version')})`
      }, {
        property: 'og:image',
        content: `${window.location.origin}${require('@/assets/images/logo.png')}`
      }]
    }
  },

  data: () => ({
  }),

  components: {
    MuCoPlayer,
    FooterNav,
    SideNav
  },

  computed: {
    ...mapGetters('users', ['isLoggedIn']),
    ...mapGetters('player', ['hasQueue', 'isFullscreen', 'hasQueue'])
  },

  mounted() {
    this.$events.listen('showError', (eventData) => this.showSnack('error', eventData))
    this.$events.listen('showInfo', (eventData) => this.showSnack('info', eventData))
    this.$events.listen('showWarning', (eventData) => this.showSnack('warning', eventData))
    this.$events.listen('showSuccess', (eventData) => this.showSnack('success', eventData))

    this.$events.listen('navigate', (eventData) => {
      this.$nextTick(() => {
        this.$router.push(eventData).catch(err => {})
      })
    })
  },

  methods: {
    // Snackbar messages
    showSnack(type, message) {
      this.$dialog.message[type](message, {
        position: (this.$vuetify.breakpoint.mobile ? 'top' : 'bottom'),
        timeout: 1500
      })
      if (!this.$vuetify.breakpoint.mobile) {
        document.documentElement.style.setProperty('--snack-bottom-margin', this.hasQueue ? '85px' : '5px');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  // Make topbar buttons ignorant to active
  .theme--dark.v-btn--active::before {
    opacity: 0 !important;
  }
  .main-background {
    background-color: var(--v-cardbackground-base);
  }
  .login-background {
    background-color: var(--v-background-base);
  }

  .slide-enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>
