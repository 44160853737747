import Lmerge from 'lodash/merge'

const mutations = {
  SET_USER(state, value) {
    state.user = value
  },
  UPDATE_USER(state, value) {
    const mergedData = Lmerge(JSON.parse(JSON.stringify(state.user)), value)
    state.user = mergedData
  }
}

export default mutations
