import Vue from 'vue'
import Lget from 'lodash/get'
import LsortBy from 'lodash/sortBy'
import Lfind from 'lodash/find'

import API from '../../plugins/api'
import i18n from '../../plugins/i18n'

const actions = {
  authenticate({ dispatch }, params) {
    Vue?.$log?.info('users store - authenticate')
    return API.auth.login(params)
      .then((result) => {
        Vue?.prototype?.$events?.fire('showSuccess', i18n.t('user.logged-in'))
        dispatch('getUser', { id: result.data.user, remember: params.remember })
          .then(() => {
            Vue.prototype.$nextTick(() => { Vue?.prototype?.$events?.fire('navigate', { name: 'Home' }) })
          })
        return true
      })
      .catch((err) => {
        const error = Lget(err, 'response.data.error', i18n.t('errors.login-failure'))
        dispatch('logout')
        Vue?.prototype?.$events?.fire('showError', error)
        return false
      })
  },

  async getUser({ commit, dispatch }, params) {
    Vue?.$log?.info('users store - getUser')
    return API.users.get(params.id)
      .then((result) => {
        i18n.locale = Lget(result, 'data.locale', 'en')
        API.setHeaders('Accept-Language', i18n.locale)

        commit('SET_USER', result.data)

        if (params.remember) {
          localStorage.setItem('user', JSON.stringify(result.data))
        } else {
          localStorage.removeItem('user')
        }
        return result.data
      })
      .catch(async (err) => {
        const error = Lget(err, 'response.data.error', i18n.t('errors.no-userdata'))
        await dispatch('logout')
        Vue?.prototype?.$events?.fire('showError', error)
        return { message: error }
      });
  },

  async loadUserQueue({ dispatch }) {
    Vue?.$log?.info('users store - loadUserQueue')
    try {
      const userQueue = JSON.parse(localStorage.getItem('userQueue'))
      if (userQueue?.rows?.length) {
        const songs = await API.songs.queuedSongs({ songIds: userQueue.rows.map((o) => o.id) })
        const queue = []
        // eslint-disable-next-line no-restricted-syntax
        for (const qi of LsortBy(userQueue.rows, ['idx'])) {
          const song = Lfind(songs.data.rows, ['id', qi.id])
          if (song) {
            queue.push({ ...song, source: qi.source })
          }
        }
        await dispatch('player/setQueue', queue, { root: true })
        await dispatch('player/setQueueIdx', userQueue.idx || 0, { root: true })
        await dispatch('player/setTrack', queue[userQueue.idx || 0], { root: true })
      }
    } catch (error) {
      // nada
    }
  },

  loadUser({ dispatch }) {
    Vue?.$log?.info('users store - loadUser')
    return new Promise((resolve) => {
      // Grab user from localstore
      try {
        const userData = JSON.parse(localStorage.getItem('user'))
        if (!(userData && userData.refreshToken)) {
          // eslint-disable-next-line no-promise-executor-return
          return resolve(dispatch('logout'))
        }
        // Get user to see if we have correct credentials
        // eslint-disable-next-line no-promise-executor-return
        return resolve(
          dispatch('getUser', { id: userData.id, remember: true })
            .then(() => {
              Vue.prototype.$nextTick(() => { Vue?.prototype?.$events?.fire('navigate', { name: 'Home' }) })
            })
        )
      } catch (err) {
        // eslint-disable-next-line no-promise-executor-return
        return resolve(dispatch('logout'))
      }
    })
  },

  async logout({ commit }) {
    Vue?.$log?.info('users store - logout')
    await API.auth.logout()
    await API.deleteHeaders('Authorization')
    await commit('SET_USER', null)
    localStorage.removeItem('user')
  },

  updateUser({ commit }, data) {
    Vue?.$log?.info('users store - updateUser')
    const user = this.getters['users/user']
    return API.users.update(user.id, data)
      .then(() => {
        commit('UPDATE_USER', data)
        Vue?.prototype?.$events?.fire('showSuccess', i18n.t('user.user-updated'))
      })
      .catch((err) => {
        const error = Lget(err, 'response.data.error', i18n.t('errors.user-update-failure'))
        Vue?.prototype?.$events?.fire('showError', error)
      })
  },

  setLocale({ dispatch }, data) {
    Vue?.$log?.info('users store - setLocale')
    const user = this.getters['users/user']
    API.setHeaders('Accept-Language', data)
    dispatch('updateUser', { id: user.id, locale: data })
    i18n.locale = data
  }
}

export default actions
