import Lreject from 'lodash/reject'

const mutations = {
  SET_RESULT(state, result) {
    state.result = result
  },
  SET_FILTER(state, filter) {
    state.filter = filter
  },
  SET_CRITERIA(state, criteria) {
    state.criteria = criteria
  },
  SET_HISTORY(state, items) {
    state.history = items
  },
  ADD_TO_HISTORY(state, item) {
    state.history.unshift(item)
  },
  REMOVE_HISTORY_ITEM(state, item) {
    state.history = Lreject(state.history, (i) => i.id === item.id && i.type === item.type)
  }
}

export default mutations
