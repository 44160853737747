<template>
  <v-btn icon @click.native.stop="toggleLike" class="like-button">
    <div v-if="hideEmpty && !isHovering && !likes"></div>
    <v-icon v-else :large="large">{{ likes ? mdiHeart : mdiHeartOutline }}</v-icon>  
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiHeart, mdiHeartOutline } from '@mdi/js'

export default {
  props: {
    id: Number,
    type: {
      type: String,
      default: 'Album',
    },
    likes: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false
    },
    hideEmpty: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiHeart,
    mdiHeartOutline,
  }),

  methods: {
    ...mapActions('albums', ['likeAlbum']),
    ...mapActions('songs', ['likeSong']),
    ...mapActions('playlists', ['likePlaylist']),
    ...mapActions('artists', ['likeArtist']),

    toggleLike() {
      if (!this.id) return
      switch(this.type) {
        case 'Song':
          this.likeSong({id: this.id, like: !this.likes})
          break;
        case 'Playlist':
          this.likePlaylist({id: this.id, like: !this.likes})
          break;
        case 'Artist':
          this.likeArtist({id: this.id, like: !this.likes})
          break;
        default:
          this.likeAlbum({id: this.id, like: !this.likes})
      }
    }
  },
}
</script>

<style scoped>
.like-button:before {
    background-color: transparent !important;
  }
</style>