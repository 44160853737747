import Vue from 'vue'
import Vuex from 'vuex'
import config from './config'

Vue.use(Vuex)

export default new Vuex.Store({
  ...config,
  actions: {
    async init({ dispatch }) {
      Vue?.$log?.info('Store - init')
      await dispatch('player/init')
      await dispatch('users/loadUser')
      await dispatch('users/loadUserQueue')
    }
  }
})
