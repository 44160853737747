const LfindIndex = require('lodash/findIndex')
const Lmerge = require('lodash/merge')
const Lget = require('lodash/get')

const mutations = {
  SET_LIBRARY_PLAYLISTS(state, items) {
    state.libraryPlaylists = items
  },
  SET_PLAYLISTS(state, playlists) {
    state.playlists = playlists
  },
  SET_PLAYLIST(state, playlist) {
    state.playlist = playlist
  },
  ADD_PLAYLIST_SONG(state, { id, track }) {
    const merged = JSON.parse(JSON.stringify(state.playlist))
    // Add to current playlist if song is added from that list
    if (Lget(state, 'playlist.id', -1) === Number(id)) {
      merged.songs.push(track)
      state.playlist = merged
    }
  },
  REMOVE_PLAYLIST_ITEM(state, data) {
    const songIdx = LfindIndex(Lget(state, 'playlist.songs', []), ['PlaylistItem.id', data.itemId])
    // Remove from current playlist if song is removed from that list
    if (songIdx > -1 && Lget(state, 'playlist.id', -1) === Number(data.id)) {
      const merged = JSON.parse(JSON.stringify(state.playlist))
      // Remove and renumber
      merged.songs.splice(songIdx, 1)
      // eslint-disable-next-line no-plusplus
      for (let i = songIdx; i < merged.songs.length; i++) {
        merged.songs[i].PlaylistItem.sortOrder -= 1
      }
      state.playlist = merged
    }
  },
  UPDATE_PLAYLIST(state, data) {
    const mergedData = Lmerge(JSON.parse(JSON.stringify(state.playlist)), data)
    state.playlist = mergedData
  },
  UPDATE_PLAYLIST_SONG(state, data) {
    const songIdx = LfindIndex(Lget(state, 'playlist.songs', []), ['id', data.id])
    if (songIdx > -1) {
      const mergedData = JSON.parse(JSON.stringify(state.playlist))
      mergedData.songs[songIdx].likes = data.like
      state.playlist = mergedData
    }
  }
}

export default mutations
