<template>
  <v-container v-if="showAnnouncement" fluid dense class="mt-0 pt-0 mx-1 announcement" v-resize="onResize">
    <v-img :src="announcementImage" height="300" class="announcement-image">
      <v-card class="d-flex justify-space-between transparent-background mt-n1" flat tile>
        <v-card :width="fillerWidth" class="transparent-background">
        </v-card>
        <div>
          <v-card class="d-flex justify-center transparent-background" flat tile>
            <AlbumItem v-for="(item, idx) in items" :key="idx" shadowed :item="item" :coverSize="180" :showTitle="false" class="mx-1" />
          </v-card>
          <div class="d-flex justify-end transparent-background mt-2">
            <div>{{ $t('announcement.news-text') }}</div>
            <v-img class="ml-1 mt-n1" contain src="@/assets/images/logo.png" max-width="30"/>
          </div>
        </div>
        <v-card :width="fillerWidth" class="transparent-background mt-3">
          <v-btn rounded :icon="fillerWidth!==150" class="announcement-btn" @click="hideAnnouncements">
            <div v-if="fillerWidth===150">{{ $t('buttons.hide-announcements') }}</div>
            <v-icon v-else>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-card>
      </v-card>
    </v-img>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import { mdiClose } from '@mdi/js'
import AlbumItem from '@/components/AlbumItem'

export default {

  /* istanbul ignore next */
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    mdiClose,
    fillerWidth: 150,
    now: new Date(),
    timerID: null
  }),

  components: {
    AlbumItem
  },

  created() {
    const self = this
    this.timerID = setInterval(function () {
         self.now = new Date()
    }, 60 * 1000)
  },

  destroyed() {
    clearInterval(this.timerID)
  },

  computed: {
    ...mapGetters('users', ['user']),

    showAnnouncement() {
      const userSetting = Lget(this, "user.options['hide-announcements']", false)
      return !this.$vuetify.breakpoint.mobile && !userSetting
    },

    announcementImage() {
      // Rotate 4 image during a day
      const d = this.now
      let imageNum = Math.floor(d.getHours()/2) + 1
      return require(`@/assets/images/announcement/an-${imageNum}.jpg`)
    }
  },

  methods: {
    ...mapActions('users', ['updateUser']),

    hideAnnouncements() {
      this.user.options['hide-announcements'] = true
      this.updateUser({
        options: this.user.options
      })
    },

    onResize(e) {
      const width = Lget(e,'target.innerWidth', this.$vuetify.breakpoint.width)
      this.fillerWidth = width < 1400 ? 50 : 150
    }
  }

}
</script>

<style scoped>
.announcement {
  height: 300px !important;
}
.announcement-btn {
  background-color: rgba(255,255,255,0.1) !important;
  color: rgba(255,255,255,0.4) !important;
}
.announcement-btn:hover {
  color: rgba(255,255,255,0.7) !important;
}
</style>
