import Lget from 'lodash/get'

const getters = {
  albumPageSize: (state) => state.pageSize,
  libraryAlbums: (state) => state.libraryAlbums,
  genreAlbums: (state) => state.genreAlbums,
  userAlbums: (state) => state.userAlbums,
  userGenres: (state) => state.userGenres,
  recommended: (state) => Lget(state, 'userAlbums.recommended.rows', []),
  latest: (state) => Lget(state, 'userAlbums.latest.rows', []),
  news: (state) => Lget(state, 'userAlbums.news.rows', []),
  album: (state) => state.album
}

export default getters
