<template>
  <v-card fill-height v-if="dataLoaded" transition="expand-transition" class="darker-background">
    <v-list exact dense class="scrollablePlaylists">
      <v-list-item link exact dense v-for="(playlist,idx) in playlists" :key="idx" :to="{ name: 'Playlist', params: {id: playlist.id } }">
        <v-list-item-title>{{ playlist.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Lget from 'lodash/get'

export default {
  data: () => ({
    benched: 0,
    dataLoaded: false
  }),

  mounted() {
    this.load()
  },

  computed: {
    ...mapGetters('playlists', ['playlists']),
  },

  methods: {
    ...mapActions('playlists', ['getUserPlaylists']),

    Lget: Lget,

    async load() {
      await this.getUserPlaylists()
      this.dataLoaded = true
    }
  }

}
</script>

<style scoped>
.v-list.scrollablePlaylists {
  height: 100%;
  overflow-y: auto;
  background-color: var(--v-background-base);
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--v-background-base); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.1); 
}

.darker-background {
  background-color: var(--v-background-base);
}
</style>