import Vue from 'vue'

import filters from './filters'

// Filters
Vue.filter('pad2digit', filters.pad2digit);
Vue.filter('minutes', filters.minutes)
Vue.filter('datetime', filters.datetime)
Vue.filter('date', filters.date)
Vue.filter('time', filters.time)
Vue.filter('bio', filters.bio)
