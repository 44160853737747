import users from './users'
import player from './player'
import albums from './albums'
import search from './search'
import songs from './songs'
import artists from './artists'
import playlists from './playlists'
import system from './system'

export default {
  modules: {
    users,
    player,
    albums,
    search,
    songs,
    artists,
    playlists,
    system
  }
}
